// 新增部门
<template>
  <el-dialog title="新增部门" :visible.sync="centerDialogVisible" @close="close" width="21%" center>
    <el-form :model="ruleForm" ref="ruleForm" label-width="120px" :inline="true" class="demo-ruleForm">
      <el-form-item label="部门ID" prop="dept_id">
        <el-input v-model="ruleForm.dept_id"></el-input>
      </el-form-item>
      <el-form-item label="部门名" prop="dept_name">
        <el-input v-model="ruleForm.dept_name"></el-input>
      </el-form-item>
      <el-form-item label="上级部门ID" prop="parent_id">
        <!-- <el-input v-model="ruleForm.parent_id"></el-input> -->
        <template>
          <el-cascader v-model="value" :options="deptall" :props="defaultProps" @change="handleChange"></el-cascader>
        </template>
      </el-form-item>
      <el-form-item label="部门显示顺序" prop="seqnum">
        <el-input v-model="ruleForm.seqnum"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'deptadd',  // 新增部门
  props: {  // 获取父组件传递过来的值
    addshow: {
      type: Boolean,
    },
  },
  watch: {
    // 监听父组件传递值的变化 赋值给子组件
    addshow (n) {
      this.centerDialogVisible = n;
    }
  },
  data () {
    return {
      value: [],  // 部门选中值
      deptall: {},   // 部门列表
      defaultProps: { // 层级选择器值
        //可以选中一级内容
        checkStrictly: true,
        //数据源parantList中的cat_id做数据绑定
        value: 'dept_id',
        //数据源parantList的cat_name渲染出来的内容
        label: 'dept_name',
        //数据源parantList的children做嵌套
        children: 'children'
      },
      deptid: [],   // 选中的部门id
      centerDialogVisible: false, // 组件是否显示
      ruleForm: {
        dept_id: '',  // 部门ID
        dept_name: '',  // 部门名
        parent_id: '',  // 上级部门ID
        seqnum: '',  // 部门显示顺序
      },
    }
  },
  created () {
    // 部门树
    this.$http.submit({
      url: '/dept/tree',
      method: 'POST',
      data: {   // 请求数据
      }
    }).then(res => {
      // 将用户列表赋值到页面
      this.deptall = res.data[2];
      // console.log('depttree', res.data[2]);
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    handleChange (value) {    // 部门选中的值
      // console.log('~~', value.slice(-1));  // 选中最后一个
      this.deptid = value.slice(-1);
    },
    // 子组件向父组件传值
    close () {
      // 向父组件传递一个方法 父组件通过操作方法修改值
      this.$emit('childadd')
    },
    // 新增
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.submit({
            url: '/dept/add',
            method: 'POST',
            data: {   // 请求数据
              dept_id: this.ruleForm.dept_id,   // 部门ID
              dept_name: this.ruleForm.dept_name,   // 部门名
              parent_id: this.deptid[0] || 0,  // 上级部门ID
              seqnum: this.ruleForm.seqnum,  // 部门显示顺序
            }
          }).then(res => {
            if (res.data[0] == 1) {
              this.$message(res.data[1]);
            } else {
              // 新增部门成功
              this.close();
              this.ruleForm.dept_id = '';
              this.ruleForm.dept_name = '';
              this.deptid[0] = '';
              this.centerDialogVisible = false;
              this.$message('新增部门成功！');
              console.log("新增部门成功！", res)
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm (formName) {
      console.log(this.deptid);
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style>
.el-form .el-form-item {
  margin-right: 30px;
}
</style>