// 所有进线
<template>
  <el-container class="elcc">
    <el-main class="elm">
      <!-- <el-header class="elh">
        <el-form :inline="true">
          <el-form-item label="编号：" label-width="60px" style="width:150px; margin-right: 10px;">
            <el-input v-model="lead_id" style="width:90px;" clearable placeholder="编号"></el-input>
          </el-form-item>
          <el-form-item label="来源：" label-width="60px" style="width:150px; margin-right: 10px;">
            <el-input v-model="leadpathvalue" style="width:90px;" clearable placeholder="来源地址"></el-input>
          </el-form-item>
          <el-form-item label="关键字：" label-width="100px" style=" margin-right: 10px;">
            <el-input v-model="leadkeywords" style="width:100px;" placeholder="关键字" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号：" label-width="80px" style=" margin-right: 10px;">
            <el-input v-model="leadmobilevalue" style="width:150px;" maxlength="11" placeholder="手机号" clearable></el-input>
          </el-form-item>
          <el-form-item label="ip：" label-width="40px" style=" margin-right: 10px;">
            <el-input v-model="leadipvalue" style="width:150px;" placeholder="ip" clearable></el-input>
          </el-form-item>
          <el-form-item label="部门：" label-width="60px" style="width:180px; margin-right: 10px;">
            <el-select v-model="leaddeptvalue" placeholder="请选择" style="width:120px;" :clearable="true">
              <el-option v-for="(item, index) in leaddept" :key="index" :label="item.dept_name" :value="item.dept_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始：" label-width="60px" style="width:200px; margin-right: 10px;">
            <el-date-picker v-model="starttime" type="date" value-format="yyyy-MM-dd" style="width:140px;" placeholder="选择开始日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束：" label-width="60px" style="width:200px; margin-right: 10px;">
            <el-date-picker v-model="endtime" type="date" value-format="yyyy-MM-dd" style="width:140px;" placeholder="选择结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="定性：" label-width="60px" style="width:180px; margin-right: 10px;">
            <el-select v-model="leadvalidvalue" placeholder="请选择" style="width:120px;" :clearable="true">
              <el-option v-for="(item, index) in leadvalid" :key="index" :label="item.model" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="width:75px; margin-right: 10px;">
            <el-button type="primary" size="medium" @click="sub">筛选</el-button>
          </el-form-item>
        </el-form>
      </el-header> -->
      <div class="shaixuan">
        <div class="sxyx" v-if="issx">
          <el-form :inline="true">
            <el-form-item
              label="编号："
              label-width="60px"
              style="width: 150px; margin-right: 10px"
            >
              <el-input
                v-model="lead_id"
                style="width: 90px"
                clearable
                placeholder="编号"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item
              label="来源："
              label-width="60px"
              style="width: 150px; margin-right: 10px"
            >
              <el-input
                v-model="leadpathvalue"
                style="width: 90px"
                clearable
                placeholder="来源地址"
              ></el-input>
            </el-form-item> -->
            <!-- <el-form-item
              label="关键字："
              label-width="100px"
              style="margin-right: 10px"
            >
              <el-input
                v-model="leadkeywords"
                style="width: 100px"
                placeholder="关键字"
                clearable
              ></el-input>
            </el-form-item> -->
            <el-form-item
              label="手机号："
              label-width="80px"
              style="margin-right: 10px"
            >
              <el-input
                v-model="leadmobilevalue"
                style="width: 150px"
                maxlength="11"
                placeholder="手机号"
                clearable
              ></el-input>
            </el-form-item>
            <!-- <el-form-item
              label="ip："
              label-width="40px"
              style="margin-right: 10px"
            >
              <el-input
                v-model="leadipvalue"
                style="width: 150px"
                placeholder="ip"
                clearable
              ></el-input>
            </el-form-item> -->
            <!-- <el-form-item
              label="ip归属地："
              label-width="100px"
              style="margin-right: 10px"
            >
              <el-input
                v-model="leadipZonevalue"
                style="width: 150px"
                placeholder="ip归属地"
                clearable
              ></el-input>
            </el-form-item> -->
            <el-form-item
              label="部门："
              label-width="60px"
              style="width: 180px; margin-right: 10px"
            >
              <el-select
                v-model="leaddeptvalue"
                placeholder="请选择"
                style="width: 120px"
                :clearable="true"
              >
                <el-option
                  v-for="(item, index) in leaddept"
                  :key="index"
                  :label="item.dept_name"
                  :value="item.dept_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="开始："
              label-width="60px"
              style="width: 200px; margin-right: 10px"
            >
              <el-date-picker
                v-model="starttime"
                type="date"
                value-format="yyyy-MM-dd"
                style="width: 140px"
                placeholder="选择开始日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="结束："
              label-width="60px"
              style="width: 200px; margin-right: 10px"
            >
              <el-date-picker
                v-model="endtime"
                type="date"
                value-format="yyyy-MM-dd"
                style="width: 140px"
                placeholder="选择结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="定性："
              label-width="60px"
              style="width: 180px; margin-right: 10px"
            >
              <el-select
                v-model="leadvalidvalue"
                placeholder="请选择"
                style="width: 120px"
                :clearable="true"
              >
                <el-option
                  v-for="(item, index) in leadvalid"
                  :key="index"
                  :label="item.model"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="width: 75px; margin-right: 10px">
              <el-button type="primary" size="medium" @click="sub"
                >筛选</el-button
              >
            </el-form-item>
          </el-form>
        </div>

        <!-- 数据表格 -->
        <el-table
          :data="listall"
          ref="uncheck"
          style="width: 100%"
          :row-class-name="tableRowClassName"
          max-height="730"
          :header-cell-style="{ background: '#333744' }"
        >
          <!-- 表未筛选弹窗操作项 -->
          <el-table-column width="50">
            <template slot="header">
              <el-button
                :icon="issx ? 'el-icon-caret-bottom' : 'el-icon-caret-right'"
                @click="screening"
                style="
                  color: #fff;
                  padding: 5px 10px;
                  font-size: 16px;
                  background: none;
                  border: none;
                "
              ></el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="lead_id"
            :show-overflow-tooltip="true"
            label="编号"
            width="150"
          >
          </el-table-column>
          <el-table-column
            label="资源进线时间"
            :show-overflow-tooltip="true"
            width="180"
          >
            <template slot-scope="scope">
              {{ scope.row.date + " " + scope.row.time }}
            </template>
          </el-table-column>
          <el-table-column
            prop="mask_mobile"
            :show-overflow-tooltip="true"
            label="电话"
            width="150"
          >
          </el-table-column>
          <!-- <el-table-column
            prop="keywords"
            :show-overflow-tooltip="true"
            label="关键字"
            width="100"
          >
          </el-table-column> -->
          <!-- <el-table-column
            prop="path"
            :show-overflow-tooltip="true"
            label="来源"
            width="100"
          >
          </el-table-column> -->
          <!-- <el-table-column
            prop="account"
            :show-overflow-tooltip="true"
            label="账户"
            width="200"
          >
          </el-table-column> -->
          <!-- <el-table-column
            prop="ip"
            :show-overflow-tooltip="true"
            label="ip"
            width="150"
          >
          </el-table-column> -->
          <!-- <el-table-column
            prop="ip_zone"
            :show-overflow-tooltip="true"
            label="ip归属地"
            width="200"
          >
          </el-table-column> -->
          <!-- <el-table-column prop="url" :show-overflow-tooltip="true" label="url">
          </el-table-column> -->
          <el-table-column
            prop="dept_id"
            :show-overflow-tooltip="true"
            label="部门id"
          >
          </el-table-column>
          <el-table-column
            prop="user_id"
            :show-overflow-tooltip="true"
            label="员工id"
          >
          </el-table-column>
          <!-- <el-table-column
            prop="valid"
            :show-overflow-tooltip="true"
            label="性质"
          >
          </el-table-column> -->

          <el-table-column
            label="性质"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.valid }}
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
          <!-- 
        size-change 当前条数发送改变时
        current-change 当前页数发生变化时
        current-page 当前页数
       -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, prev, pager, next, sizes"
            :total="count"
            prev-text="上1页"
            next-text="下1页"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
export default {
  name: "listall", // 所有进线
  data() {
    return {
      issx: false, // 筛选弹窗是否显示
      currentPage: 1, // 当前页
      pageSize: 20, // 每页显示条数
      count: 0, // 总个数
      listall: [], // 数据
      userinfo: [], // 当前用户数据
      // 筛选
      lead_id: "", // 根据数据id筛选
      leadpathvalue: "", // 线索来源选中的值
      leadpath: [], // 线索来源数组
      leadmobilevalue: "", // 线索筛选中的手机号
      leadipvalue: "", // 根据ip筛选
      leadipZonevalue: "", // 根据ip归属地筛选
      leaddeptvalue: "", // 筛选分配部门选中的值
      leaddept: [], // 筛选部门数组
      leadkeywords: "", // 关键字筛选的值
      leadvalidvalue: "", // 数据源类型
      leadvalid: [
        { value: 1, model: "正常" },
        { value: 3, model: "测试" },
        { value: 4, model: "可疑" },
        { value: 5, model: "同行" },
        { value: 8, model: "特殊处理" },
      ],
      starttime: "", // 开始时间
      endtime: "", // 结束时间
      filterArr: {}, // 筛选的值
    };
  },
  created() {
    /**
     * 取出存储在缓存中的用户信息
     * JSON.parse(localStorage.getItem('userinfo'))
     */
    let userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.userinfo = userinfo;

    // 获取所有进线数据
    this.$http
      .submit({
        url: "/lead/pool/all",
        method: "POST",
        data: {
          // 请求数据
          page: this.currentPage, // 当前显示页数
          pagesize: this.pageSize, // 每页显示条数
        },
      })
      .then((res) => {
        // 数据
        // console.log(res);
        res = res.data[2];
        this.currentPage = res.page; // 当前页数
        // 将新增进线列表存到未分配进线
        this.listall = res.data; // 进线数据
        this.count = res.count; // 总条数
        // 根据性质显示
        if (this.listall == "" || this.listall == null || this.listall == []) {
          console.log("没有数据");
        } else {
          this.listall.forEach((item) => {
            if (item.valid == 1) {
              item.valid = "";
            } else if (item.valid == 3) {
              item.valid = "测试";
            } else if (item.valid == 4) {
              item.valid = "可疑";
            } else if (item.valid == 5) {
              item.valid = "同行";
            } else if ( item.valid == 8) {
              item.valid = "暂不处理";
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // 线索来源地址
    // 页面加载就获取数据
    // this.$http
    //   .submit({
    //     url: "/lead/page/all",
    //     method: "POST",
    //     data: {
    //       // 请求数据
    //     },
    //   })
    //   .then((res) => {
    //     this.leadpath = res.data[2];
    //     // res.data[2].forEach(item => {
    //     //   this.leadpath.push(item.page_path);    // 线索来源地址
    //     // })
    //     // console.log(this.leadpath);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  methods: {
    // 表格筛选弹窗显示按钮
    screening() {
      this.issx = !this.issx;
      // 数据筛选打开时，获取部门信息
      if (this.issx == true) {
        this.$http
          .submit({
            url: "/dept/children", 
            method: "POST",
            data: {
              // 请求数据
              dept_id: this.userinfo.dept_id,
              // pagesize: 100,
              // page: 1
            },
          })
          .then((res) => {
            // 将下级部门赋值到数组
            // console.log(res.data);
            this.leaddept = res.data[2]; // 筛选中的下级部门
            // console.log('dept:', this.leaddept);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // console.log('表格筛选了:', this.issx);
    },

    // 确认筛选按钮
    sub() {
      // console.log(this.leadpathvalue, '-----', this.leaddeptvalue, '-----', this.starttime, '-----', this.endtime);
      // console.log('leadvalidvalue：', this.leadvalidvalue);
      // 筛选的条件
      let filters = {
        lead_id: this.lead_id, // 线索id
        path: this.leadpathvalue, // 来源
        keywords: this.leadkeywords, // 关键字筛选的值
        mobile: this.leadmobilevalue, // 手机号
        ip: this.leadipvalue, // ip
        ip_zone: this.leadipZonevalue, // ip归属地
        dept_id: this.leaddeptvalue, // 部门
        starttime: this.starttime, // 开始时间
        endtime: this.endtime, // 结束时间
        valid: this.leadvalidvalue, // 数据源提交 1-正常 3-测试 4-可疑 5-同行 8-特殊处理
      };
      this.filterArr = filters; // 记录筛选的值

      this.$http
        .submit({
          url: "/lead/pool/all",
          method: "POST",
          data: {
            // 请求数据
            page: this.currentPage, // 当前显示页数
            pagesize: this.pageSize, // 每页显示条数
            filter: this.filterArr, // 筛选值
          },
        })
        .then((res) => {
          // 数据
          // console.log(res);
          res = res.data[2];
          this.currentPage = res.page; // 当前页数
          // 将新增进线列表存到未分配进线
          this.listall = res.data; // 进线数据
          this.count = res.count; // 总条数
          // 根据性质显示
          if (
            this.listall == "" ||
            this.listall == null ||
            this.listall == []
          ) {
            console.log("没有数据");
          } else {
            this.listall.forEach((item) => {
              if (item.valid == 1) {
                item.valid = "";
              } else if (item.valid == 3) {
                item.valid = "测试";
              } else if (item.valid == 4) {
                item.valid = "可疑";
              } else if (item.valid == 5) {
                item.valid = "同行";
              } else if ( item.valid == 8) {
                item.valid = "暂不处理";
              }
            });
          }
          // 筛选成功后隐藏弹窗
          this.issx = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 斑马线
    tableRowClassName({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return "warning-row";
      }
    },
    // 页面列表显示条数发送变化后
    handleSizeChange: function (pageSize) {
      // console.log('页面条数发生变化');
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currentPage);
      this.$http
        .submit({
          url: "/lead/pool/all",
          method: "POST",
          data: {
            // 请求数据
            pagesize: this.pageSize,
            filter: this.filterArr, // 筛选值
          },
        })
        .then((res) => {
          // 将新增进线列表存到未分配进线
          res = res.data[2];
          this.listall = res.data; // 进线数据
          this.count = res.count; // 总条数
          // 根据性质显示
          if (
            this.listall == "" ||
            this.listall == null ||
            this.listall == []
          ) {
            console.log("没有数据");
          } else {
            this.listall.forEach((item) => {
              if (item.valid == 1) {
                item.valid = "";
              } else if (item.valid == 3) {
                item.valid = "测试";
              } else if (item.valid == 4) {
                item.valid = "可疑";
              } else if (item.valid == 5) {
                item.valid = "同行";
              } else if ( item.valid == 8) {
                item.valid = "暂不处理";
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 列表页数发生变化后
    handleCurrentChange: function (currentPage) {
      // console.log('页数发送变化');
      // 当前页数改变时 将页数值赋值到页面
      this.currentPage = currentPage;
      this.$http
        .submit({
          url: "/lead/pool/all",
          method: "POST",
          data: {
            // 请求数据
            page: this.currentPage,
            pagesize: this.pageSize,
            filter: this.filterArr, // 筛选值
          },
        })
        .then((res) => {
          // 将新增进线列表存到未分配进线
          res = res.data[2];
          this.currentPage = res.page; // 当前页
          this.listall = res.data; // 进线数据
          this.count = res.count; // 总条数
          // 根据性质显示
          if (
            this.listall == "" ||
            this.listall == null ||
            this.listall == []
          ) {
            console.log("没有数据");
          } else {
            this.listall.forEach((item) => {
              if (item.valid == 1) {
                item.valid = "";
              } else if (item.valid == 3) {
                item.valid = "测试";
              } else if (item.valid == 4) {
                item.valid = "可疑";
              } else if (item.valid == 5) {
                item.valid = "同行";
              } else if ( item.valid == 8) {
                item.valid = "暂不处理";
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.shaixuan {
  position: relative;
  width: 100%;
}
.sxyx {
  position: absolute;
  width: 100%;
  /* height: 100px; */
  z-index: 9;
  background: #e3e5e8;
  top: 35px;
  text-align: initial;
}
.sxyx .el-form {
  padding: 20px 10px 0;
}

.elcc .elm .elh {
  clear: both;
  background: #fff;
  position: absolute;
  z-index: 9;
  height: auto !important;
  line-height: 40px;
  margin-bottom: 5px;
  margin-top: -15px;
  padding: 0;
}
.elcc .elm .elh .el-form .el-form-item {
  margin-bottom: 15px;
}
.el-table .warning-row {
  background: #eee;
}
.el-table .success-row {
  background: #f0f9eb;
}
</style>