// 推广部门自动分配规则 - 后天
<template>
  <div>
    <el-table :data="ruleall" ref="uncheck" max-height="750" :row-class-name="tableRowClassName" :header-cell-style="{ background: '#333744' }">
      <!-- <el-table-column prop="id" label="编号">
      </el-table-column> -->
      <el-table-column prop="enable" label="是否启用">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enable" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949" @change="changeSwitch(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column label="本部门">
        <template>
          {{thisdept.from_dept_name + '(' + thisdept.from_dept_id + ')'}}
        </template>
      </el-table-column>
      <el-table-column label="下级部门">
        <template slot-scope="scope">
          {{scope.row.to_dept_name + '(' + scope.row.to_dept_id + ')'}}
        </template>
      </el-table-column>
      <el-table-column prop="target_num" label="计划数">
        <template slot-scope="scope">
          <el-input type="text" v-model="scope.row.target_num" placeholder="请设置计划数量" @change="inputsub(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="num" label="已完成">
      </el-table-column>
      <!-- 表未刷新表单操作项 -->
      <el-table-column width="50">
        <template slot="header">
          <el-button type="primary" icon="el-icon-refresh-right" @click="refresh" style="padding: 5px 10px; font-size: 16px"></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: 'leadRuleTopromote',    // 推广部门自动分配规则
  data () {
    return {
      msg: '',
      userinfo: [],   // 当前用户信息
      thisdept: {},    // 当前本部门
      ruleall: [],    // 当前下级规则表
      date: '',   // 计划提交的日期
    }
  },
  created () {
    /**
         * 取出存储在缓存中的用户信息
         * JSON.parse(localStorage.getItem('userinfo'))
         */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // console.log("用户信息:", this.userinfo);

    // 获取后天日期
    let today = new Date(); // 今天
    let afterday = new Date(today.valueOf() + 172800000); // 后天
    let mtYear = afterday.getFullYear(); // 得到年份
    let mtMonth = afterday.getMonth() + 1; // 得到月份
    if (mtMonth < 10) mtMonth = "0" + mtMonth;
    let mtDate = afterday.getDate();
    if (mtDate < 10) mtDate = "0" + mtDate;
    let afterdaytime = mtYear + '-' + mtMonth + '-' + mtDate;
    this.date = afterdaytime;   // 后天
    // console.log('后天', this.date);

    // 页面加载 获取规则列表信息
    this.$http.submit({
      url: '/lead/auto/form/head',
      method: 'POST',
      data: {   // 请求数据
        from_dept_id: this.userinfo.dept_id,   // 部门名
        date: this.date,    // 计划时间
        type: 1,    // 请求类型 1-部门表 2-员工表
      }
    }).then(res => {
      // console.log('leadAutoForm：', res);
      // 将规则表赋值到数组
      // console.log(res.data[2], '-------', res.data[2].rules);
      this.thisdept = {   // 本部门信息
        from_dept_id: res.data[2].from_dept_id,
        from_dept_name: res.data[2].from_dept_name,
      };
      // 下级部门信息
      this.ruleall = res.data[2].rules;
    }).catch(err => {
      console.log(err);
    })

  },
  methods: {

    // 是否启用按钮 - 点击按钮切换是否启用
    changeSwitch (row) {
      // console.log(row.enable, '--', row.from_dept_id, '--', row.to_dept_id, '--', row.target_num);
      this.$http.submit({
        url: '/lead/auto/submit',
        method: 'POST',
        data: {   // 请求数据
          enable: row.enable,   // 是否启用 0-禁用 1-启用
          from_dept_id: row.from_dept_id,   // 从这个部门
          to_dept_id: row.to_dept_id,   // 到这个部门
          target_num: row.target_num,   // 分配目标
          date: this.date,    // 计划时间
        }
      }).then(res => {
        console.log('leadAutoSubmit：', res);
        // 将规则表赋值到数组
        // console.log(res.data[2], '-------', res.data[2].rules);
      }).catch(err => {
        console.log(err);
      })
    },

    // 计划书输入框失去焦点事件
    inputsub (item) {
      // console.log(item.enable, '---', item.from_dept_id, '---', item.to_dept_id, '---', item.target_num);
      // if (item.target_num == null || item.target_num == '') {
      //   console.log("什么都没有做");
      // } else {
      this.$http.submit({
        url: '/lead/auto/submit',
        method: 'POST',
        data: {   // 请求数据
          enable: item.enable,   // 是否启用 0-禁用 1-启用
          from_dept_id: item.from_dept_id,   // 从这个部门
          to_dept_id: item.to_dept_id,   // 到这个部门
          target_num: item.target_num,   // 分配目标
          date: this.date,    // 计划时间
        }
      }).then(res => {
        console.log('leadAutoSubmit：', res);
        // 将规则表赋值到数组
        // console.log(res.data[2], '-------', res.data[2].rules);
      }).catch(err => {
        console.log(err);
      })
      // }
    },


    // 刷新表格按钮
    refresh () {
      // 刷新规则列表信息
      this.$http.submit({
        url: '/lead/auto/form/head',
        method: 'POST',
        data: {   // 请求数据
          from_dept_id: this.userinfo.dept_id,   // 部门名
          date: this.date,    // 计划时间
          type: 1,    // 请求类型 1-部门表 2-员工表
        }
      }).then(res => {
        // console.log('leadAutoForm：', res);
        // 将规则表赋值到数组
        // console.log(res.data[2], '-------', res.data[2].rules);
        this.thisdept = {   // 本部门信息
          from_dept_id: res.data[2].from_dept_id,
          from_dept_name: res.data[2].from_dept_name,
        };
        // 下级部门信息
        this.ruleall = res.data[2].rules;
      }).catch(err => {
        console.log(err);
      })
    },

    // 斑马线+状态背景色
    tableRowClassName ({ rowIndex }) {
      // console.log(row.enable, '---', rowIndex);
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return 'warning-row'
      }
    },
  }
}
</script>

<style>
/* 斑马线的多种状态 */
.el-table .gjz-row {
  background: #e1d7d7;
}
.el-table .yyx-row {
  background: #a8c7ee;
}
.el-table .wyx-row {
  background: #f2b7b7;
}
.el-table .cg-row {
  background: #b0ea93;
}
.el-table__column-filter-trigger {
  line-height: 1;
}
</style>