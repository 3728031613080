// 列出所有部门
<template>
  <el-table :data="deptall" style="width: 100%" max-height="750" :header-cell-style="{ background: '#333744' }">
    <el-table-column prop="dept_id" :show-overflow-tooltip="true" label="部门ID">
    </el-table-column>
    <el-table-column prop="dept_name" :show-overflow-tooltip="true" label="部门名">
    </el-table-column>
    <!-- <el-table-column prop="dept_level" :show-overflow-tooltip="true" label="部门级别">
    </el-table-column> -->
    <!-- <el-table-column prop="seqnum" :show-overflow-tooltip="true" label="部门显示顺序" width="120">
    </el-table-column> -->
    <el-table-column prop="leader_id" :show-overflow-tooltip="true" label="部门主管id">
    </el-table-column>
    <!-- <el-table-column prop="leader_id" :show-overflow-tooltip="true" label="部门主管id">
    </el-table-column> -->
    <el-table-column prop="leader_name" :show-overflow-tooltip="true" label="部门主管">
    </el-table-column>
    <!-- <el-table-column prop="dept1_id" :show-overflow-tooltip="true" label="一级部门ID">
    </el-table-column> -->
    <el-table-column prop="dept1_name" :show-overflow-tooltip="true" label="一级部门名">
    </el-table-column>
    <!-- <el-table-column prop="dept2_id" :show-overflow-tooltip="true" label="二级部门ID">
    </el-table-column> -->
    <el-table-column prop="dept2_name" :show-overflow-tooltip="true" label="二级部门名">
    </el-table-column>
    <!-- <el-table-column prop="dept3_id" :show-overflow-tooltip="true" label="三级部门ID">
    </el-table-column> -->
    <el-table-column prop="dept3_name" :show-overflow-tooltip="true" label="三级部门名">
    </el-table-column>
    <!-- <el-table-column prop="dept_fullname" :show-overflow-tooltip="true" label="部门全名">
    </el-table-column> -->
    <el-table-column label="操作" width="160">
      <template slot-scope="scope">
        <el-button size="mini" type="primary" @click="handleEdit(scope.$index, deptall)">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  name: 'deptall',    // 列出所有部门
  data () {
    return {
      deptall: [], // 所有部门
      userinfo: {},   // 用户信息
    }
  },
  created () {
    this.bus.$on('deptif', data => {    // 修改部门成功后
      this.deptall = data
    })
    this.bus.$on('deptall', data => {   // 新增部门成功后
      this.deptall = data
    })

    /**
    * 取出存储在缓存中的用户信息
    * JSON.parse(localStorage.getItem('userinfo'))
    */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // 页面加载就获取数据
    this.$http.submit({
      url: '/dept/children',
      method: 'POST',
      data: {   // 请求数据
        dept_id: this.userinfo.dept_id,
        // pagesize: 100,
        // page: 1
      }
    }).then(res => {
      // 将所有部门赋值到页面
      // console.log(res.data);
      this.deptall = res.data[2];
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    // 编辑部门
    handleEdit (index, row) {
      // console.log('编辑', row[index]);

      // 将修改的部门信息传递给兄弟组件-修改部门
      this.bus.$emit('deptinfo', row[index])

      this.bus.$emit('editshow', true)
    }
  }
}
</script>