<template>
  <el-dialog
    title="修改部门用户信息"
    :visible.sync="centerDialogVisible"
    @close="close"
    width="21%"
    center
  >
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="6rem"
      :inline="true"
      class="demo-ruleForm"
    >
      <!-- <el-form-item label="部门ID" prop="dept_id">
        <el-input v-model="ruleForm.dept_id"></el-input>
      </el-form-item> -->
      <el-form-item label="用户名：" prop="name">
        <span>{{ ruleForm.name }}</span>
        <!-- <el-input v-model="ruleForm.name"></el-input> -->
      </el-form-item>
      <el-form-item label="职位：" prop="position">
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in position"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属部门：" prop="deptAllPosition">
        <el-select v-model="deptallValue" placeholder="请选择">
          <el-option
            v-for="item in deptAllPosition"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "deptuseredit", // 部门设置员工
  data() {
    return {
      userall: [], // 修改成功后的部门用户信息
      userlistall: [], // 修改成功后的下级部门用户信息
      centerDialogVisible: false, // 组件是否显示
      ruleForm: {
        dept_id: "", // 部门ID
        user_id: "", // 用户ID
        position: "", // 职位
      },
      value: 0, // 职员职位选中
      position: [
        {
          value: 0,
          label: "普通职员",
        },
        {
          value: 1,
          label: "初级职员",
        },
        {
          value: 2,
          label: "高级职员",
        },
        {
          value: 3,
          label: "副主管",
        },
        {
          value: 4,
          label: "主管",
        },
      ],
      deptallValue: [], // 用户下级部门ID
      deptAllPosition: [], // 用户下级部门列表
    };
  },
  created() {
    // 编辑员工传过来的员工ID
    this.bus.$on("deptuserinfo", (data) => {
      // console.log("deptuserinfo", data);
      this.ruleForm = data;

      // console.log("**",this.value);
    });
    // 编辑员工点击是否显示弹窗
    this.bus.$on("editshow", (data) => {
      this.centerDialogVisible = data;
    });

    // console.log("打开了员工编辑列表", this.centerDialogVisible);
  },
  watch: {
    centerDialogVisible(i, x) {
      /**
       * 取出存储在缓存中的用户信息
       * JSON.parse(localStorage.getItem('userinfo'))
       */
      let userinfo = JSON.parse(localStorage.getItem("userinfo"));
      // 每次请求之前先清空所属部门列表
      this.deptAllPosition = [];
      // console.log("/////", this.deptAllPosition, userinfo);

      if (i == true) {
        this.value = parseInt(this.ruleForm.position); // 默认返回 position 字段为字符串类型，需要转译为数字类型赋值给职位默认值
        // console.log(this.ruleForm.position,"====");

        // 当弹窗打开时则请求用户ID下的所有部门列表 用于调整部门员工所属部门信息
        // console.log(i, x, "=========");
        this.$http
          .submit({
            url: "/dept/children",
            method: "POST",
            data: {
              // 请求数据
              dept_id: userinfo.dept_id,
              // pagesize: 100,
              // page: 1
            },
          })
          .then((res) => {
            // 将所有部门赋值到页面
            // console.log("部门员工", res.data);
            // this.deptall = res.data[2];
            this.deptAllPosition.push({
              value: userinfo.dept_id,
              label: userinfo.dept_name,
            });
            if (res.data == "" || res.data == null || res.data == []) {
              alert("用户没有下级部门");
            } else {
              res.data[2].forEach((item) => {
                // console.log("item", item);
                this.deptAllPosition.push({
                  value: item.dept_id,
                  label: item.dept_name,
                });
              });
            }
            this.deptallValue = this.ruleForm.dept_id;
            // console.log("新的部门列表数组",this.deptAllPosition, this.position);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // 当关闭弹窗则不调整信息
        console.log(i, x, "xxxxxx");
      }
    },
  },
  methods: {
    // 子组件向父组件传值
    close() {
      // 向父组件传递一个方法 父组件通过操作方法修改值
      this.$emit("childedit");
    },
    // 新增
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(
            this.ruleForm.id,
            this.deptallValue,
            this.ruleForm.user_id,
            this.value
          );
          this.$http
            .submit({
              url: "/deptuser/edit",
              method: "POST",
              data: {
                // 请求数据
                id: this.ruleForm.id, // 数据原始ID
                dept_id: this.deptallValue, // 部门ID
                user_id: this.ruleForm.user_id, // 用户id
                position: this.value, // 用户职位
              },
            })
            .then((res) => {
              // 新增部门员工成功
              this.close();
              this.centerDialogVisible = false;
              this.$message("修改部门用户成功！");
              console.log("修改部门用户成功！", res);

              // 修改部门用户成功后 - 部门用户列表
              let userinfo = JSON.parse(localStorage.getItem("userinfo"));
              this.$http
                .submit({
                  url: "/deptuser/all",
                  method: "POST",
                  data: {
                    // 请求数据
                    dept_id: userinfo.dept_id,
                  },
                })
                .then((res) => {
                  // 将用户列表赋值到页面
                  console.log(res);
                  this.userall = res.data[2];
                  if (
                    this.userall == "" ||
                    this.userall == null ||
                    this.userall == []
                  ) {
                    console.log("没有部门员工");
                  } else {
                    this.userall.forEach((item) => {
                      if (item.position == 0) {
                        item.position = "普通职员";
                      } else if (item.position == 1) {
                        item.position = "初级职员";
                      } else if (item.position == 2) {
                        item.position = "高级职员";
                      } else if (item.position == 3) {
                        item.position = "副主管";
                      } else if (item.position == 4) {
                        item.position = "主管";
                      }
                    });
                  }
                  this.bus.$emit("userall", this.userall);
                  // console.log("userall：", userall);
                })
                .catch((err) => {
                  console.log(err);
                });

              // 修改部门用户成功后 - 部门用户列表
              this.$http
                .submit({
                  url: "/deptuser/listall",
                  method: "POST",
                  data: {
                    // 请求数据
                    dept_id: userinfo.dept_id,
                  },
                })
                .then((res) => {
                  // 将用户列表赋值到页面
                  // console.log(res);
                  this.userlistall = res.data[2];
                  if (
                    this.userlistall == "" ||
                    this.userlistall == null ||
                    this.userlistall == []
                  ) {
                    console.log("没有部门员工");
                  } else {
                    this.userlistall.forEach((item) => {
                      if (item.position == 0) {
                        item.position = "普通职员";
                      } else if (item.position == 1) {
                        item.position = "初级职员";
                      } else if (item.position == 2) {
                        item.position = "高级职员";
                      } else if (item.position == 3) {
                        item.position = "副主管";
                      } else if (item.position == 4) {
                        item.position = "主管";
                      }
                    });
                  }
                  this.bus.$emit("userlistall", this.userlistall);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style>
.el-form .el-form-item {
  margin-right: 1rem;
}
</style>