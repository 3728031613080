<template>
  <div>
    <div class="title" style="font-size: 20px; font-weight: bold; text-align: initial; margin: 20px auto;">同行上报</div>
    <div class="demo-ruleForm">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" :inline="true" class="elform" style="text-align: left;">
        <el-form-item label="线索编号：" label-width="100px" style="width:190px; margin-right: 15px; margin-bottom: 0;">
          <el-input v-model="ruleForm.lead_id" placeholder="请输入线索编号" style="width:90px;"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" label-width="80px" style="width:230px; margin-right: 15px; margin-bottom: 0;">
          <el-input v-model="ruleForm.mobile" placeholder="请输入同行手机号" style="width:150px;"></el-input>
        </el-form-item>
        <!-- <el-form-item label="ip：" label-width="80px" style="width:230px; margin-right: 15px; margin-bottom: 0;">
          <el-input v-model="ruleForm.ip" placeholder="请输入同行ip" style="width:150px;"></el-input>
        </el-form-item> -->
        <el-form-item label="理由：" label-width="80px" style=" margin-right: 15px; margin-bottom: 0;">
          <el-input v-model="ruleForm.reason" placeholder="请输入上报原因"></el-input>
        </el-form-item>
        <el-form-item style=" margin-right: 15px; margin-bottom: 0;">
          <el-button type="primary" @click="onSubmit">上报</el-button>
        </el-form-item>
      </el-form>
      <p style="text-align: initial; padding:10px 20px; color: red; font-weight: bold;">请务必谨慎操作，确定为同行后再进行上报。如后期发现提交错误，请联系管理员处理。</p>
    </div>
    <div class="title" style="font-size: 20px; font-weight: bold; text-align: initial; margin: 20px auto;">上报记录</div>
    <div class="jg">
      <table>
        <thead>
          <tr>
            <th>上报时间</th>
            <th>线索id</th>
            <th>手机号</th>
            <!-- <th>ip</th> -->
            <!-- <th>ip归属地</th> -->
            <th>理由</th>
            <th>负责部门id</th>
            <th>负责员工id</th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in reportrecord" :key="index">
          <tr>
            <td> {{item.ctime}} </td>
            <td> {{item.lead_id}} </td>
            <td> {{item.mobile}} </td>
            <!-- <td> {{item.ip}} </td> -->
            <!-- <td> {{item.ip_zone}} </td> -->
            <td> {{item.reason}} </td>
            <td> {{ item.dept_name + '(' + item.dept_id + ')' }} </td>
            <td> {{ item.user_name + '(' + item.user_id + ')' }} </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "leadPeerReport",   // 部门经理的同行上报
  data () {
    return {
      ruleForm: {},   // 上报数据
      reportrecord: [],   // 上报记录
    }
  },
  created () {

    // 同行上报记录
    this.$http.submit({
      url: '/blocklist/history',
      method: 'POST',
      data: {   // 请求数据
      }
    }).then(res => {
      // console.log('res', res.data[2]);
      this.reportrecord = res.data[2];    // 上报记录
    }).catch(err => {
      console.log(err);
    })

  },
  methods: {
    onSubmit () {
      // console.log('线索上报成功', this.ruleForm.lead_id, '--', this.ruleForm.mobile);
      // 同行上报
      this.$http.submit({
        url: '/blocklist/add',
        method: 'POST',
        data: {   // 请求数据
          lead_id: this.ruleForm.lead_id,   // 线索id
          mobile: this.ruleForm.mobile,   // 手机号
          ip: this.ruleForm.ip,   // 同行ip
          reason: this.ruleForm.reason,   // 上报原因
        }
      }).then(res => {
        if (res.data[0] == 0) {
          this.$message('同行信息上报成功');
          // 同行上报记录
          this.$http.submit({
            url: '/blocklist/history',
            method: 'POST',
            data: {   // 请求数据
            }
          }).then(res => {
            this.reportrecord = res.data[2];    // 上报记录
          }).catch(err => {
            console.log(err);
          })
        } else {
          this.$message(res.data[1]);
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }
}
</script>
<style>
.jg table {
  border: 1px solid #ccc;
  border-spacing: 0px;
  border-collapse: collapse;
}

.jg td,
.jg th {
  padding: 10px 20px;
  border: 1px solid #ccc;
}
.jg tr td:last-child {
  text-align: right;
}
</style>