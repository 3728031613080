// 同行报告
<template>
  <div>
    <!-- <p style="font-size: 20px; font-weight: bold; text-align: initial; margin: 20px auto;">最近31天内可疑ip</p> -->
    <fieldset style="padding:10px 15px; width:300px; float:left; margin:0 15px;">
      <legend>最近31天内可疑ip</legend>
      <el-input type="textarea" autosize v-model="blocklistip"></el-input>
    </fieldset>
    <fieldset style="padding:10px 15px; float:left;">
      <legend>同行IP封禁列表 (共{{blocklist.length}}条) </legend>
      <div class="jg">
        <table>
          <thead>
            <tr>
              <th>编号</th>
              <th>开始时间</th>
              <th>截止时间</th>
              <!-- <th>ip</th> -->
              <!-- <th>ip归属地</th> -->
              <th>封禁原因</th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in blocklist" :key="index">
            <tr>
              <td> {{ item.id }} </td>
              <td> {{ item.ctime }} </td>
              <td> {{ item.exptime }} </td>
              <!-- <td> {{ item.ip}} </td> -->
              <!-- <td> {{ item.ip_zone}} </td> -->
              <td> {{ item.reason}} </td>

            </tr>
          </tbody>
        </table>
      </div>
    </fieldset>

  </div>
</template>
<script>
export default {
  name: 'leadpeerreport',   // 同行报告
  data () {
    return {
      blocklist: '',    // 同行信息
      blocklistip: '',    // 同行IP信息
    }
  },
  created () {

    // 页面加载获取同行ip
    this.$http.submit({
      url: '/blocklist/iplist',
      method: 'POST',
      data: {   // 请求数据
      }
    }).then(res => {
      this.blocklist = res.data[2].list;    // 同行信息
      this.blocklistip = res.data[2].iplist;    // 同行信息
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    listdc () {
      console.log('导出');
    }
  }
}
</script>
<style>
.jg {
  margin-bottom: 20px;
}
.jg table {
  border: 1px solid #ccc;
  border-spacing: 0px;
  border-collapse: collapse;
}

.jg td,
.jg th {
  padding: 10px 20px;
  border: 1px solid #ccc;
}
.jg tr td:last-child {
  text-align: right;
}
</style>