// 新增网站规则
<template>
  <el-dialog title="新增网站" :visible.sync="centerDialogVisible" @close="close" width="35%" center>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" :inline="true" class="demo-ruleForm">
      <el-form-item label="网站地址" prop="page_path">
        <el-input v-model="ruleForm.page_path"></el-input>
      </el-form-item>
      <el-form-item label="网站名称" prop="page_name">
        <el-input v-model="ruleForm.page_name"></el-input>
      </el-form-item>
      <el-form-item label="网站类型" prop="page_type">
        <el-input v-model="ruleForm.page_type"></el-input>
      </el-form-item>
      <el-form-item label="通道" prop="channel">
        <el-input v-model="ruleForm.channel"></el-input>
      </el-form-item>
      <el-form-item label="账号" prop="account">
        <el-input v-model="ruleForm.account"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'leadpageadd',    // 新增网站规则
  props: {  // 获取父组件传递过来的值
    addshow: {
      type: Boolean,
    },
  },
  watch: {
    // 监听父组件传递值的变化 赋值给子组件
    addshow (n) {
      this.centerDialogVisible = n;
    }
  },
  data () {
    return {
      centerDialogVisible: false, // 组件是否显示
      ruleForm: {
        page_path: '',   // 网站地址 -- /ZG01
        page_name: '',   // 网站名称 -- 百度信息流02
        page_type: '',   // 网站类型 -- pc / wap 
        channel: '',  // 通道 -- 百度 / 360 / 头条
        account: '',   // 账户 -- 百度大搜12户 / 百度信息流-
      },
      rules: {

      }
    }
  },
  methods: {

    // 子组件向父组件传值
    close () {
      // 向父组件传递一个方法 父组件通过操作方法修改值
      this.$emit('childadd')
    },
    // 新增
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 新增网站
          this.$http.submit({
            url: '/lead/page/add',
            method: 'POST',
            data: {   // 请求数据
              page_path: this.ruleForm.page_path,   // 网站地址
              page_name: this.ruleForm.page_name,   // 网站名称
              page_type: this.ruleForm.page_type,   // 网站类型
              channel: this.ruleForm.channel,   // 通道
              account: this.ruleForm.account,   // 账号
            }
          }).then(res => {
            // 成功后关闭弹窗
            this.close()
            // 新增用户成功
            this.$message('新增网站成功！');
            console.log("新增网站成功！", res)
          }).catch(err => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style>
.el-form .el-form-item {
  margin-right: 30px;
}
.el-input--suffix .el-input__inner {
  padding-right: 15px;
}
</style>