// 修改员工登录名和密码
<template>
  <el-dialog title="修改用户登录账号密码" :visible.sync="centerDialogVisible" @close="close" width="20%" center>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
      <!-- <el-form-item label="用户ID" prop="user_id">
        <el-input v-model="ruleForm.user_id"></el-input>
      </el-form-item> -->
      <el-form-item label="登录名" prop="login_id">
        <el-input v-model="ruleForm.login_id"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pwdhash">
        <el-input v-model="ruleForm.pwdhash"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkpwdhash">
        <el-input v-model="ruleForm.checkpwdhash"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">立即修改</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'usereditlogin',   // 修改员工登录名密码
  props: {  // 获取父组件传递过来的值
    editloginshow: {
      type: Boolean,
    },
  },
  watch: {
    // 监听父组件传递值的变化 赋值给子组件
    editloginshow (n) {
      this.centerDialogVisible = n;
    }

  },
  data () {
    // 判断两次输入密码是否正确
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkpwdhash !== '') {
          this.$refs.ruleForm.validateField('checkpwdhash');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pwdhash) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      centerDialogVisible: false, // 组件是否显示
      ruleForm: {
        user_id: '',   // 用户ID -- V
        login_id: '',   // 登录名 -- V
        pwdhash: '',   // 登录密码 -- V
        checkpwdhash: '',  // 确认密码
      },
      rules: {
        user_id: [
          { required: true, message: '请输入修改用户ID', trigger: 'blur' },
        ],
        login_id: [
          { required: true, message: '请输入修改后的登录名', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        pwdhash: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        checkpwdhash: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
      }
    }
  },
  created () {
    this.bus.$on('loginuserygshow', data => {
      this.centerDialogVisible = data
    })
    this.bus.$on('loginuseryginfo', data => {
      this.ruleForm = data
    })
    console.log(this.ruleForm);

  },
  methods: {
    // 子组件向父组件传值
    close () {
      // 向父组件传递一个方法 父组件通过操作方法修改值
      this.$emit('childeditlogin')
    },
    // 新增
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 新增用户
          // 登录密码
          let pwdhash = this.$md5(this.ruleForm.login_id + this.ruleForm.pwdhash);
          this.$http.submit({
            url: '/user/editlogin',
            method: 'POST',
            data: {   // 请求数据
              user_id: this.ruleForm.user_id,   // 用户ID
              login_id: this.ruleForm.login_id,   // 登录名
              pwdhash: pwdhash,  // 登录密码
            }
          }).then(res => {
            // 成功后关闭弹窗
            this.close()
            // 新增用户成功
            this.centerDialogVisible = false
            this.$message('修改用户登录名或密码成功');
            console.log("修改用户登录名或密码成功!", res);
          }).catch(err => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style>
.el-form .el-form-item {
  margin-right: 30px;
}
</style>