// 系统容器
<template>
  <el-container>
    <el-aside width="210px" :class="isCollapse ? 'app-side-collapsed' : 'app-side-expanded'">
      <!-- 切换侧边栏状态按钮 -->
      <div class="qh" @click.prevent="isCollapse = !isCollapse">
        <i v-show="!isCollapse" class="el-icon-s-fold"></i>
        <i v-show="isCollapse" class="el-icon-s-unfold"></i>
      </div>
      <!-- 头像 -->
      <el-row class="demo-avatar demo-basic" style="margin: 50px auto 10px">
        <div class="block">
          <el-avatar :size="isCollapse ? 30 : 60" :src="circleUrl" title="用户"></el-avatar>
        </div>
      </el-row>
      <!-- 用户信息 -->
      <div class="username" v-if="!isCollapse">
        <span>{{ userinfo.name }}</span>
        <span @click="logOut" class="user-icon el-icon-switch-button" title="退出登录"></span><br>
        <span>员工号：{{ userinfo.user_id }}</span>

        <!-- <p>登录时间：<span class="user-time">{{ userinfo.update_time }}</span></p> -->
      </div>
      <!-- 侧边栏菜单项 -->
      <el-menu default-active="0" class="el-menu-vertical-demo" unique-opened @open="handleOpen" @close="handleClose"
        :collapse="isCollapse" background-color="#333744" text-color="#fff">
        <div v-for="(item, index) in menus" :key="index">
          <el-submenu v-if="item.children" :index="item.menu_id" style="text-align: left;">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{item.name}}</span>
            </template>
            <div v-for="(itemI, indexI) in item.children" :key="indexI">
              <el-menu-item-group style="text-align: left;min-width: 130px; padding: 0;">
                <el-menu-item :index="itemI.menu_id" @click="currentTab = itemI.currentTab.toString()">{{itemI.name}}
                </el-menu-item>
              </el-menu-item-group>
            </div>
          </el-submenu>
          <el-menu-item v-else :index="item.menu_id" @click="currentTab = item.currentTab.toString()"
            style="text-align: left;">
            <i :class="item.icon"></i>
            <span slot="title">{{item.name}}</span>
          </el-menu-item>
        </div>
      </el-menu>
    </el-aside>
    <el-main>
      <div class="content-container">
        <component :is="iscomponent"></component>
      </div>
    </el-main>
  </el-container>
</template>
<script>

import home from '../home/index.vue'   // 首页容器
import dept from '../dept/index.vue'   // 部门管理容器 -- 需要设置user_id
import deptsz from '../deptsz/index.vue'   // 部门设置容器 -- 不需要user_id
import user from '../user/index.vue'   // 用户容器
import editmm from '../user/editmm.vue'   // 用户密码
import userlogin from '../user/editlogin.vue'
import deptuser from '../deptuser/index.vue'    // 部门管理用户容器
import deptusersz from '../deptusersz/index.vue'    // 部门设置用户容器
import lead from '../lead/index.vue'   // 资源容器
import leadpage from '../leadpage/index.vue'    // 网站设置

// 线索菜单
import leadfollowall from '../lead/follow/all/list.vue'   // 业务经理菜单 - 线索中的线索总览
import leadfollowmy from '../lead/follow/my/list.vue'   // 业务经理菜单 - 线索中的我的线索
import leadfollowhis from '../lead/follow/his/list.vue'   // 业务经理菜单 - 线索中的手动分配
import leadautorule from '../lead/follow/auto/index.vue'    // 业务经理菜单 - 线索中的自动分配
import leadrecycling from '../lead/recycling/index.vue'   // 业务经理菜单 - 管理中的线索回收

// 同行上报
import leadpeerreport from '../lead/peerreport/index.vue'   // 业务经理菜单 - 线索中的同行上报
import leadpeerall from '../lead/peerreport/peerall.vue'    // 业务经理菜单 - 管理中的同行列表
import leadpeerbg from '../lead/peerreport/report.vue'    // 推广菜单 - 同行报告

// 报表
import leadreportpromote from '../lead/report/topromote.vue'   // 推广报表
import leadreportteam from '../lead/report/team.vue'   // 业务 - 团队报表
import leadreportpersonal from '../lead/report/personal.vue'   // 业务 - 个人报表

// 自动分配规则
// import leadruletopromote from '../lead/rule/topromote.vue'    // 推广部门自动分配规则
import leadruletopromote from '../lead/list/index.vue'    // 推广部门自动分配规则
import leadruleteam from '../lead/rule/team.vue'    // 业务 - 自动分配到部门
import leadrulepersonal from '../lead/rule/personal.vue'    // 业务 - 自动分配到员工
import leadiprule from '../lead/iprule/index.vue'    // 推广 - 按照ip区域分配到指定部门


// 测试账号 - 白名单
import testaccount from '../test/test.vue'    // 设置菜单 - 测试账号


export default {
  name: 'container',
  data() {
    return {
      new: '',  // 这一分钟新的进线数
      todo: '',   // 有多少条未跟进
      countif: '',    // 用来监听数据是否发送变化
      // timer: null,    // 页面的定时器
      isCollapse: false,  // 按钮状态
      userinfo: {},   // 缓存取到的用户信息
      circleUrl: "http://sh.tougu7.com/crmwebimg/user.jpg", // 头像
      com_list: ["home", "dept", "deptsz", "user", "userlogin", "editmm", "deptuser", "deptusersz", "lead", "testaccount", "leadfollowall", "leadpeerbg", "leadpeerall", "leadfollowhis", "leadrecycling", "leadfollowmy", "leadautorule", "leadpeerreport", "leadpage", "leadreportpromote", "leadreportteam", "leadreportpersonal", "leadruletopromote", "leadruleteam", "leadrulepersonal", "leadiprule"],  // 多个路由间切换
      currentTab: "home", // 默认路由值
      menus: []
    }
  },
  components: {
    home, // 首页
    dept, // 部门
    deptsz, // 部门设置
    user, // 用户
    userlogin, // 修改登录名和密码
    editmm, // 修改密码
    deptuser, // 部门用户
    deptusersz, // 部门用户设置
    lead,   // 资源
    leadpage,   // 网站设置
    leadreportpromote,   // 推广报表
    leadreportteam,   // 团队报表
    leadreportpersonal,   // 个人报表

    // 业务经理菜单的 - 线索
    leadfollowmy,   // 业务经理菜单 - 线索中的我的线索
    leadfollowall,    // 业务经理菜单 - 线索中的线索总览
    leadfollowhis,   // 业务经理菜单 - 线索中的线索分配
    leadautorule,   // 业务经理菜单 - 自动分配规则
    leadrecycling,    // 业务经理菜单 - 管理中的线索回收

    // 同行
    leadpeerreport,   // 业务经理菜单 - 管理中的同行上报
    leadpeerall,     // 业务经理菜单 - 管理中的同行列表
    leadpeerbg, // 推广菜单 - 同行报告

    // 自动分配规则
    leadruletopromote,    // 推广部门自动分配规则
    leadruleteam,   // 业务经理菜单 - 自动分配到部门
    leadrulepersonal,   // 业务经理菜单 - 自动分配到个人
    leadiprule,   // 推广 - 按照ip区域分配到指定部门

    // 设置菜单
    testaccount,    // 测试账号
  },
  computed: {
    iscomponent() {  // 点击路由切换
      return this.currentTab.toLowerCase();
    },
  },
  created() {
    // 超级管理员
    let menu1 = [{
      menu_id: '0',
      icon: 'el-icon-s-home',
      name: '首页',
      currentTab: 'home'
    },
    {
      menu_id: '1',
      icon: 'el-icon-s-promotion',
      name: '推广',
      children: [
        {
          menu_id: '101',
          name: '进线列表',
          currentTab: 'lead',
          children: [{
            menu_id: '10101',
            name: '全部进线'
          },
          {
            menu_id: '10102',
            name: '未分配进线'
          },
          {
            menu_id: '10103',
            name: '已分配进线'
          },
          ]
        },
        {
          menu_id: '102',
          name: '推广页',
          currentTab: 'leadpage'
        },
        {
          menu_id: '103',
          name: '分配规则',
          currentTab: 'leadruletopromote'
        },

        // {
        //   menu_id: '104',
        //   name: '同行报告',
        //   currentTab: 'leadpeerbg'
        // },
        {
          menu_id: '104',
          name: '同行上报',
          currentTab: 'leadpeerreport',
        },
        {
          menu_id: '105',
          name: '同行列表',
          currentTab: 'leadpeerbg',
        },
        // {
        //   menu_id: '106',
        //   name: '按IP分配',
        //   currentTab: 'leadiprule'
        // },
        {
          menu_id: '107',
          name: '报表',
          currentTab: 'leadreportpromote'
        },
      ]
    },
    {
      menu_id: '2',
      icon: 'el-icon-paperclip',
      name: '线索',
      children: [
        {
          menu_id: '201',
          name: '线索总览',
          currentTab: 'leadfollowall',
          children: [{
            menu_id: '20101',
            name: '待跟进'
          },
          {
            menu_id: '20102',
            name: '跟进中'
          },
          {
            menu_id: '20103',
            name: '有意向'
          },
          {
            menu_id: '20104',
            name: '无意向'
          },
          ]
        },
        {
          menu_id: '202',
          name: '我的线索',
          todo: this.todo,
          currentTab: 'leadfollowmy',
          children: [{
            menu_id: '20201',
            name: '分配到下级部门'
          },
          {
            menu_id: '20202',
            name: '分配到员工'
          },
          ]
        },
        {
          menu_id: '203',
          name: '线索分配',
          currentTab: 'leadfollowhis',
          children: [{
            menu_id: '20301',
            name: '分配到下级部门'
          },
          {
            menu_id: '20302',
            name: '分配到员工'
          },
          ]
        },
        {
          menu_id: '204',
          name: '自动分配',
          currentTab: 'leadautorule'
        },
        {
          menu_id: '205',
          name: '团队报表',
          currentTab: 'leadreportteam'
        },
        {
          menu_id: '206',
          name: '个人报表',
          currentTab: 'leadreportpersonal'
        },
      ]
    },
    {
      menu_id: '3',
      icon: 'el-icon-s-check',
      name: '管理',
      children: [{
        menu_id: '301',
        name: '部门管理',
        currentTab: 'dept'
      },
      {
        menu_id: '302',
        name: '员工管理',
        currentTab: 'deptuser'
      },
      {
        menu_id: '303',
        name: '线索回收',
        currentTab: 'leadrecycling'
      },
      {
        menu_id: '304',
        name: '同行上报',
        currentTab: 'leadpeerreport',
      },
      {
        menu_id: '305',
        name: '同行列表',
        currentTab: 'leadpeerall',
      },
      ]
    },
    {
      menu_id: '4',
      icon: 'el-icon-s-tools',
      name: '设置',
      children: [{
        menu_id: '401',
        name: '部门管理',
        currentTab: 'deptsz'
      },
      {
        menu_id: '402',
        name: '员工管理',
        currentTab: 'deptusersz'
      },
      {
        menu_id: '403',
        name: '测试账号',
        currentTab: 'testaccount'
      },
      ]
    },
    {
      menu_id: '5',
      icon: 'el-icon-user-solid',
      name: '我的',
      children: [{
        menu_id: '501',
        name: '个人资料',
        currentTab: 'user'
      },
      {
        menu_id: '502',
        name: '修改密码',
        currentTab: 'editmm'
      },
      ]
    },
    ];
    // 推广
    let menu2 = [{
      menu_id: '0',
      icon: 'el-icon-s-home',
      name: '首页',
      currentTab: 'home'
    },
    {
      menu_id: '1',
      icon: 'el-icon-s-promotion',
      name: '推广',
      children: [{
        menu_id: '101',
        name: '进线列表',
        currentTab: 'lead',
        children: [{
          menu_id: '10101',
          name: '全部进线'
        },
        {
          menu_id: '10102',
          name: '未分配进线'
        },
        {
          menu_id: '10103',
          name: '已分配进线'
        },
        ]
      },
      {
        menu_id: '102',
        name: '推广页',
        currentTab: 'leadpage'
      },
      {
        menu_id: '103',
        name: '分配规则',
        currentTab: 'leadruletopromote'
      },
      // {
      //   menu_id: '104',
      //   name: '同行报告',
      //   currentTab: 'leadpeerbg'
      // },
      {
        menu_id: '104',
        name: '同行上报',
        currentTab: 'leadpeerreport',
      },
      {
        menu_id: '105',
        name: '同行列表',
        currentTab: 'leadpeerbg',
      },
      // {
      //   menu_id: '106',
      //   name: '按IP分配',
      //   currentTab: 'leadiprule'
      // },
      {
        menu_id: '107',
        name: '报表',
        currentTab: 'leadreportpromote'
      },
      ]
    },
    {
      menu_id: '5',
      icon: 'el-icon-user-solid',
      name: '我的',
      children: [{
        menu_id: '501',
        name: '个人资料',
        currentTab: 'user'
      },
      {
        menu_id: '502',
        name: '修改密码',
        currentTab: 'editmm'
      },
      ]
    },
    ];
    // 部门经理
    let menu3 = [{
      menu_id: '0',
      icon: 'el-icon-s-home',
      name: '首页',
      currentTab: 'home'
    },
    {
      menu_id: '2',
      icon: 'el-icon-paperclip',
      name: '线索',
      children: [
        {
          menu_id: '201',
          name: '线索总览',
          currentTab: 'leadfollowall',
          children: [{
            menu_id: '20101',
            name: '待跟进'
          },
          {
            menu_id: '20102',
            name: '跟进中'
          },
          {
            menu_id: '20103',
            name: '有意向'
          },
          {
            menu_id: '20104',
            name: '无意向'
          },
          ]
        },
        {
          menu_id: '202',
          name: '我的线索',
          todo: this.todo,
          currentTab: 'leadfollowmy',
          children: [{
            menu_id: '20201',
            name: '分配到下级部门'
          },
          {
            menu_id: '20202',
            name: '分配到员工'
          },
          ]
        },
        {
          menu_id: '203',
          name: '线索分配',
          currentTab: 'leadfollowhis',
          children: [{
            menu_id: '20301',
            name: '分配到下级部门'
          },
          {
            menu_id: '20302',
            name: '分配到员工'
          },
          ]
        },
        {
          menu_id: '204',
          name: '自动分配',
          currentTab: 'leadautorule'
        },
        {
          menu_id: '205',
          name: '团队报表',
          currentTab: 'leadreportteam'
        },
        {
          menu_id: '206',
          name: '个人报表',
          currentTab: 'leadreportpersonal'
        },
      ]
    },
    {
      menu_id: '3',
      icon: 'el-icon-s-check',
      name: '管理',
      children: [{
        menu_id: '301',
        name: '部门管理',
        currentTab: 'dept'
      },
      {
        menu_id: '302',
        name: '员工管理',
        currentTab: 'deptuser'
      },
      {
        menu_id: '303',
        name: '线索回收',
        currentTab: 'leadrecycling'
      },
      {
        menu_id: '304',
        name: '同行上报',
        currentTab: 'leadpeerreport',
      },
      {
        menu_id: '305',
        name: '同行列表',
        currentTab: 'leadpeerall',
      },
      ]
    },
    {
      menu_id: '5',
      icon: 'el-icon-user-solid',
      name: '我的',
      children: [{
        menu_id: '501',
        name: '个人资料',
        currentTab: 'user'
      },
      {
        menu_id: '502',
        name: '修改密码',
        currentTab: 'editmm'
      },
      ]
    },
    ];
    // 业务员
    let menu4 = [{
      menu_id: '0',
      icon: 'el-icon-s-home',
      name: '首页',
      currentTab: 'home'
    },
    {
      menu_id: '2',
      icon: 'el-icon-paperclip',
      name: '线索',
      children: [{
        menu_id: '201',
        name: '我的线索',
        todo: this.todo,
        currentTab: 'leadfollowmy',
        children: [{
          menu_id: '20101',
          name: '待跟进'
        },
        {
          menu_id: '20102',
          name: '跟进中'
        },
        {
          menu_id: '20103',
          name: '有意向'
        },
        {
          menu_id: '20104',
          name: '无意向'
        },
        ]
      },
      {
        menu_id: '204',
        name: '个人报表',
        currentTab: 'leadreportpersonal'
      },
      ]
    },
    {
      menu_id: '5',
      icon: 'el-icon-user-solid',
      name: '我的',
      children: [{
        menu_id: '501',
        name: '个人资料',
        currentTab: 'user'
      },
      {
        menu_id: '502',
        name: '修改密码',
        currentTab: 'editmm'
      },
      ]
    },
    ];

    /**
     * 取出存储在缓存中的用户信息
     * JSON.parse(localStorage.getItem('userinfo'))
     */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // console.log("用户信息:", this.userinfo);

    // 获取用户menu_type
    this.$http.submit({
      url: '/user/info',
      method: 'POST',
      data: {   // 请求数据
        user_id: this.userinfo.user_id,   // 提交用户登录名
      }
    }).then(res => {
      // console.log('用户菜单', res.data[2].menu_type);
      let resmt = res.data[2].menu_type;    // 根据用户menu_type 显示菜单
      if (resmt == 1) {
        // 超级管理
        this.menus = menu1;
      } else if (resmt == 2) {
        // 推广
        this.menus = menu2;
      } else if (resmt == 3) {
        // 部门经理
        this.menus = menu3;
      }
      else if (resmt == 4) {
        // 普通职员
        this.menus = menu4;
      }

      this.countInfo();
    }).catch(err => {
      console.log(err);
    })


  },
  watch: {    // 监听数值是否变化
    countif() {   // 当数据发生变化时执行一次定时器
      this.timer()
    }
  },
  destroyed() {    // 销毁定时器
    clearTimeout(this.timer)
  },
  methods: {
    // 获取我的线索未跟进数量和每分钟进线数据 以及菜单列表
    countInfo() {
      this.$http.submit({
        url: '/follow/count/todo',
        method: 'POST',
        data: {   // 请求数据
          user_id: this.userinfo.user_id,   // 提交用户登录名
        }
      }).then(res => {
        this.new = res.data[2].new;   // 这一分钟新的进线
        this.new_time = res.data[2].new_time;   // 这一分钟新的进线
        this.todo = res.data[2].todo;   // 未跟进条数
        this.countif = {
          new: res.data[2].new,   // 这一分钟新的线索数量
          new_time: res.data[2].new_time,   // 这一分钟新的线索数量调取时间
          todo: res.data[2].tode,

        }
        // console.log(this.new, '---', this.todo);
        if (res.data[2].new == 0) {  // 判断是否有线索进线
          // console.log('没有新的线索进来');
        } else {
          this.open()
        }

      }).catch(err => {
        console.log(err);
      });
    },
    // 这是一个定时器
    timer() {
      return setTimeout(() => {
        this.countInfo()
        if (this.new == 0) {
          // console.log('没有新的进线', this.new);
        } else {
          // console.log('有新的资源进线', this.new);
        }
      }, 1000 * 60)
    },
    // 这一分钟新的进线 -- 提示框
    open() {
      // let s = <span style="color:red;">this.countif.new</span>

      this.$notify({
        title: '提示',
        message: "<span class='msgcls'>" + this.countif.new_time + '</span>' + '你有' + this.countif.new + '条新的线索， 请及时跟进。',
        // message: '你有' + this.countif.new + '条新的线索， 请及时跟进。',
        dangerouslyUseHTMLString: true,   // 允许使用html代码
        duration: 0,    // 显示时间  设置0 则不会关闭

      });
    },
    handleOpen(key, keyPath) {
      key;
      keyPath
      // console.log('展开', key, keyPath);
    },
    handleClose(key, keyPath) {
      key;
      keyPath
      // console.log('关闭', key, keyPath);
    },
    // 登出账号 auth/logout
    logOut() {
      this.$http.submit({
        url: '/auth/logout',
        method: 'POST',
        data: {   // 请求数据
          login_id: this.userinfo.login_id,   // 提交用户登录名
        }
      }).then(res => {
        if (res.data[0] == 0) {
          this.$router.push({ path: "/login" });
          // 清除存储在缓存中的用户信息
          localStorage.removeItem('usertoken')
        } else {
          this.$message(res.data[1]);
        }
      }).catch(err => {
        console.log(err);
      })
    },
  }
}
</script>

<style>
.msgcls {
  /** 右上角提示框的样式 */
  font-size: 12px;
  color: #666;
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 70px;
}

.el-container {
  height: 100%;
}

/* 头部 */
.el-header {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

/* 侧边 */
.el-aside {
  background-color: #333744;
  color: #fff;
  text-align: center;
}

.el-aside .qh {
  font-size: 25px;
  width: 35px;
  float: right;
  cursor: pointer;
}

.el-submenu__icon-arrow {
  right: 20px;
}

/* 用户信息 */
.el-aside .username {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.el-aside .username span {
  text-align: center;
  padding: 0 5px;
  line-height: 25px;
  font-size: 14px;
}

.el-aside .user-icon {
  color: #ccc;
  cursor: pointer;
}

.el-aside .username p {
  font-size: 11px;
  white-space: nowrap;
}

/* 菜单区域侧边线 */
.el-aside .el-menu {
  border: none;
}

/* n内容 */
.el-container .el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  padding: 15px 20px;
}

.app-side-collapsed {
  width: 66px !important;
  transition: width 1s;
}

.app-side-expanded {
  width: 150px !important;
  transition: width 1s;
}

.el-submenu .el-menu-item {
  padding: 0;
  min-width: 130px;
}

/*隐藏文字*/
.el-menu--collapse .el-submenu__title span {
  display: none;
}

/*隐藏 > */
.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
</style>