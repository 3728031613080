<template>
  <el-dialog title="新增部门员工" :visible.sync="centerDialogVisible" @close="close" width="35%" center>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" :inline="true" class="demo-ruleForm">
      <el-form-item label="部门：" prop="dept_id">
        <template>
          <el-cascader v-model="value" :options="deptall" :props="defaultProps" @change="handleChange"></el-cascader>
        </template>
      </el-form-item>
      <el-form-item label="职位：" prop="position">
        <el-select v-model="valuez" placeholder="请选择">
          <el-option v-for="item in position" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="登录名" prop="login_id">
        <el-input v-model="ruleForm.login_id"></el-input>
      </el-form-item>
      <el-form-item label="登录密码" prop="pwdhash">
        <el-input v-model="ruleForm.pwdhash"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkpwdhash">
        <el-input v-model="ruleForm.checkpwdhash"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="ruleForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="email" prop="email">
        <el-input v-model="ruleForm.email"></el-input>
      </el-form-item>
      <el-form-item label="微信号" prop="wxid">
        <el-input v-model="ruleForm.wxid"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="ruleForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="分机" prop="ext">
        <el-input v-model="ruleForm.ext"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-input v-model="ruleForm.gender"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idnum">
        <el-input v-model="ruleForm.idnum"></el-input>
      </el-form-item>
      <el-form-item label="生日" prop="birthday">
        <el-input v-model="ruleForm.birthday"></el-input>
      </el-form-item>
      <el-form-item label="头像" prop="avatar">
        <el-input v-model="ruleForm.avatar"></el-input>
      </el-form-item>
      <el-form-item label="家庭地址" prop="home_address">
        <el-input v-model="ruleForm.home_address"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'deptuseradd',  // 添加部门用户
  props: {  // 获取父组件传递过来的值
    addshow: {
      type: Boolean,
    },
  },
  watch: {
    // 监听父组件传递值的变化 赋值给子组件
    addshow (n) {
      this.centerDialogVisible = n;
    }

  },
  data () {
    // 判断两次输入密码是否正确
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkpwdhash !== '') {
          this.$refs.ruleForm.validateField('checkpwdhash');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pwdhash) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      value: [],  // 部门选中值
      deptall: [],   // 部门列表
      defaultProps: { // 层级选择器值
        //可以选中一级内容
        checkStrictly: true,
        //数据源parantList中的cat_id做数据绑定
        value: 'dept_id',
        //数据源parantList的cat_name渲染出来的内容
        label: 'dept_name',
        //数据源parantList的children做嵌套
        children: 'children',
        // 数组选中的值只会打印最后一项
        emitPath: false,
      },
      userinfo: {},   // 缓存取到的用户信息
      deptid: [],   // 选中的部门id
      centerDialogVisible: false, // 组件是否显示
      ruleForm: {
        dept_id: '',  // 部门ID
        position: '',  // 职位
        name: '',   // 姓名 -- V
        login_id: '',   // 登录名 -- V
        pwdhash: '',   // 登录密码 -- V
        checkpwdhash: '',  // 确认密码
        mobile: '',   // 手机号 -- V
        email: '',    // 邮箱
        wxid: '',   // 微信号
        phone: '',   // 联系电话
        ext: '',    // 分机
        gender: '',   // 性别
        idnum: '',    // 身份证号
        birthday: '',   // 生日
        avatar: '',   // 头像
        home_address: '',   // 家庭地址
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          // { min: 3, message: '长度必须大于3位', trigger: 'blur' }
        ],
        login_id: [
          { required: true, message: '请输入登录名', trigger: 'blur' },
          { min: 4, message: '长度必须大于4位', trigger: 'blur' }
        ],
        pwdhash: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        checkpwdhash: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
      },
      valuez: 0,  // 职员职位选中
      position: [
        {
          value: 0,
          label: '普通职员',
        },
        {
          value: 1,
          label: '初级职员',
        },
        {
          value: 2,
          label: '高级职员',
        },
        {
          value: 3,
          label: '副主管',
        },
        {
          value: 4,
          label: '主管',
        },
      ],
      userall: [],

    }
  },
  created () {
    /**
     * 取出存储在缓存中的用户信息
     * JSON.parse(localStorage.getItem('userinfo'))
     */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // console.log("用户信息:", this.userinfo);
    this.value = userinfo.dept_id;
    // 部门树
    this.$http.submit({
      url: '/dept/thistree',
      method: 'POST',
      data: {   // 请求数据
        dept_id: this.userinfo.dept_id
      }
    }).then(res => {
      // 将用户列表赋值到页面
      console.log(res.data);
      this.deptall.push(res.data[2])
      // console.log('depttree', res.data[2]);
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    handleChange (value) {    // 部门选中的值
      this.value = value;
    },

    // 子组件向父组件传值
    close () {
      // 向父组件传递一个方法 父组件通过操作方法修改值
      this.$emit('childadd')
    },
    // 新增
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 新增用户
          // 登录密码
          console.log("新增部门员工，部门id：", this.value, "，员工职位：", this.valuez, "，员工姓名：", this.ruleForm.name, "，登录名：", this.ruleForm.login_id, "，登录密码：", this.ruleForm.pwdhash);
          let pwdhash = this.$md5(this.ruleForm.login_id + this.ruleForm.pwdhash);
          this.$http.submit({
            url: '/dept/add/user',
            method: 'POST',
            data: {   // 请求数据
              dept_id: this.value,   // 部门ID
              position: this.valuez,  // 职位
              name: this.ruleForm.name,   // 姓名
              login_id: this.ruleForm.login_id,   // 登录名
              pwdhash: pwdhash,  // 登录密码
              mobile: this.ruleForm.mobile,  // 手机号
              email: this.ruleForm.email,   // 邮箱
              wxid: this.ruleForm.wxid,   // 微信号
              phone: this.ruleForm.phone,   // 联系电话
              ext: this.ruleForm.ext,   // 分机
              gender: this.ruleForm.gender, // 性别
              idnum: this.ruleForm.idnum, //身份证号
              birthday: this.ruleForm.birthday, // 生日
              avatar: this.ruleForm.avatar, // 头像
              home_address: this.ruleForm.home_address  // 家庭地址
            }
          }).then(res => {
            // 新增部门员工成功
            this.close();
            this.valuez = 0;
            this.ruleForm = {};
            this.centerDialogVisible = false
            this.$message('新增部门员工成功！');
            console.log("新增部门员工成功！", res);


          }).catch(err => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm (formName) {
      // console.log('---', this.value);
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style>
.el-form .el-form-item {
  margin-right: 30px;
}
</style>