// 线索自动分配
<template>
  <div style='position: relative;'>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="分配到部门" name="autoteam">
        <autoteam :key="timer1" v-if="activeName == 'autoteam'"></autoteam>
      </el-tab-pane>
      <el-tab-pane label="分配到员工" name="autopersonal">
        <autopersonal :key="timer2" v-if="activeName == 'autopersonal'"></autopersonal>
      </el-tab-pane>
    </el-tabs>
    <el-button type="primary" size="medium" style="position: absolute;right:10px;top:2px;" @click="perform">执行一次自动分配</el-button>
  </div>
</template>
<script>
import autopersonal from '../../rule/personal.vue'    // 业务经理菜单 - 自动分配到个人
import autoteam from '../../rule/team.vue'    // 业务经理菜单 - 自动分配到部门
export default {
  name: 'leadAutoRule',     // 线索自带分配
  data () {
    return {
      activeName: 'autoteam',  // 默认选中的选项卡name
      timer: '', // 点击规则时更新组件
      userinfo: [],   // 获取当前用户信息
      timer1: '',
      timer2: '',
    }
  },
  components: {
    autopersonal,    // 业务经理菜单 - 自动分配到个人
    autoteam,   // 业务经理菜单 - 自动分配到部门
  },
  created () {
    /**
         * 取出存储在缓存中的用户信息
         * JSON.parse(localStorage.getItem('userinfo'))
         */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // console.log("用户信息:", this.userinfo);
  },
  methods: {
    handleClick (tab) {  // 切换后刷新
      // console.log("=== ",this.activeName, tab.name);
      // this.timer = new Date().getTime()

      let name = tab.name;
      if (name == 'autoteam') {
        this.timer1 = new Date().getTime()
      } else if (name == 'autopersonal') {
        this.timer2 = new Date().getTime()
      }
    },
    perform () {
      // console.log('执行了执行一次自动分配');
      // 页面加载 获取规则列表信息
      
      this.$http.submit({
        url: '/lead/dispatch/apply',
        method: 'POST',
        data: {   // 请求数据
          dept_id: this.userinfo.dept_id,
        }
      }).then(res => {
        if(res.data[0] == 0) {
          this.$message('执行一次自动分配成功，正在刷新最新数据！');
          if (this.activeName == 'autoteam') {
            this.timer1 = new Date().getTime()
          } else if (this.activeName == 'autopersonal') {
            this.timer2 = new Date().getTime()
          }
        }else {
          this.$message('执行一次自动分配失败，请重新点击！');
        }
      }).catch(err => {
        console.log(err);
      })
    }

  }
}
</script>
<style>
/* .el-tabs__header {
  padding-right: 75px !important;
} */
</style>