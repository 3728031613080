// 表单组件
<template>
  <div>
    <el-form class="elform efil" :mobel="myfields" label-width="150px" :inline="true">
      <el-form-item class="elformitem" v-for="(item, index) in myfields" :key="index" :label="item.label + ':'">
        <div v-if="item.type == ''">
          <el-input v-model="item.value" :placeholder="item.placeholder" :value="item.value == null ? '' : item.value" @blur="inputsub(index, $event)"></el-input> <br>
          <span style="color: red; font-size:12px;">
            {{item.utime == null ? '' : item.utime}}
            {{item.user_name == null ? '' : item.user_name}}
            {{item.user_id == null ? '' : '(' + item.user_id + ')'}}
          </span>

        </div>
        <div v-else-if="item.type == 'enum'">
          <el-radio v-for="(itemI, indexI) in JSON.parse(item.values)" :key="indexI" v-model="item.value" :value="item.value === null ? item.default : item.value" :label="indexI.toString()" @change="radiosub(index, $event)">
            {{itemI}}
          </el-radio> <br>
          <span style="color: red; font-size:12px;">
            {{item.utime == null ? '' : item.utime}}
            {{item.user_name == null ? '' : item.user_name}}
            {{item.user_id == null ? '' : '(' + item.user_id + ')'}}
          </span>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'formcomponent',
  props: {
    leadid: {
      type: String
    },
  },
  data () {
    return {
      myfields: [],
    }
  },
  created () {
    let myfields = {};

    // 返回表单所有字段
    this.$http.submit({
      url: '/follow/data/form',
      method: 'POST',
      data: {   // 请求数据
        form_id: 1, // form类型
      }
    }).then(res => {
      // console.log('获取表单所有字段', res);
      // console.log('form', res.data[2]);
      // 数据
      let fields = res.data[2];
      fields.forEach(field => {
        myfields[field["field"]] = field;
      })
      // console.log(Object.keys(myfields));
      let hq = Object.keys(myfields)
      // console.log(hq);
      //  先获取表单字段 后获取表单所有字段值
      this.$http.submit({
        url: '/follow/data/getall',
        method: 'POST',
        data: {   // 请求数据
          lead_id: this.leadid, // form类型
          // fields: ["jieting", "jiawei", "wx"],
          fields: hq,
        }
      }).then(res => {
        // console.log('getall', res);
        // 数据
        let rows = res.data[2];
        rows.forEach(row => {
          // console.log('row:', row.field);
          if (row.field in myfields) {
            // 表单数组值追加
            myfields[row["field"]]["value"] = row["value"];
            myfields[row["field"]]["user_name"] = row["user_name"];
            myfields[row["field"]]["user_id"] = row["user_id"];
            myfields[row["field"]]["utime"] = row["utime"];
          }

        });
        // console.log("myfields", myfields);
        this.myfields = myfields;
      }).catch(err => {
        console.log(err);
      })
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    inputsub (index, $event) {
      // if ($event.target.value == '') {
      //   console.log('没有值可以提交');
      // } else {
      // console.log('valuey', this.myfields[index].value, 'valuex', $event.target.value);   // 输入框value
      this.$http.submit({
        url: '/follow/data/set',
        method: 'POST',
        data: {   // 请求数据
          lead_id: this.leadid,  // 进线ID
          type: '',
          field: index,
          value: $event.target.value
        }
      }).then(res => {
        // 数据
        console.log('跟进结果', res);
        // 跟进成功后将最新的跟进记录显示出来
        let hq = Object.keys(this.myfields)
        // console.log(hq);
        //  先获取表单字段 后获取表单所有字段值
        this.$http.submit({
          url: '/follow/data/getall',
          method: 'POST',
          data: {   // 请求数据
            lead_id: this.leadid, // form类型
            // fields: ["jieting", "jiawei", "wx"],
            fields: hq,
          }
        }).then(res => {
          // console.log('getall', res);
          // 数据
          let rows = res.data[2];
          rows.forEach(row => {
            // console.log('row:', row.field);
            if (row.field in this.myfields) {
              // 表单数组值追加
              this.myfields[row["field"]]["value"] = row["value"];
              this.myfields[row["field"]]["user_name"] = row["user_name"];
              this.myfields[row["field"]]["user_id"] = row["user_id"];
              this.myfields[row["field"]]["utime"] = row["utime"];
            }
          });
          // console.log("myfields", this.myfields);
          // this.myfields = myfields;
        }).catch(err => {
          console.log(err);
        })
      }).catch(err => {
        console.log(err);
      })
      // }
    },
    radiosub (index, $event) {
      // console.log(index, '===', $event);
      this.$http.submit({
        url: '/follow/data/set',
        method: 'POST',
        data: {   // 请求数据
          lead_id: this.leadid,  // 进线ID
          type: 'enum',
          field: index,
          value: $event,
        }
      }).then(res => {
        // 数据
        console.log('跟进结果', res);
        // 跟进成功后将最新的跟进记录显示出来
        let hq = Object.keys(this.myfields)
        // console.log(hq);
        //  先获取表单字段 后获取表单所有字段值
        this.$http.submit({
          url: '/follow/data/getall',
          method: 'POST',
          data: {   // 请求数据
            lead_id: this.leadid, // form类型
            // fields: ["jieting", "jiawei", "wx"],
            fields: hq,
          }
        }).then(res => {
          // console.log('getall', res);
          // 数据
          let rows = res.data[2];
          rows.forEach(row => {
            // console.log('row:', row.field);
            if (row.field in this.myfields) {
              // 表单数组值追加
              this.myfields[row["field"]]["value"] = row["value"];
              this.myfields[row["field"]]["user_name"] = row["user_name"];
              this.myfields[row["field"]]["user_id"] = row["user_id"];
              this.myfields[row["field"]]["utime"] = row["utime"];
            }
          });
          console.log("myfields", this.myfields);
          // this.myfields = myfields;
        }).catch(err => {
          console.log(err);
        })
      }).catch(err => {
        console.log(err);
      })
    },

    an () {
      console.log('--------', this.myfields);
    }
  }
}
</script>
<style>
.elform .elformitem {
  width: 520px;
}
.efil .el-form-item {
  margin-bottom: 5px;
}
.efil .el-form-item .el-form-item__label {
  font-weight: bold;
  color: blue;
  line-height: 30px;
}
.efil .el-form-item__content {
  line-height: 30px;
}

.efil .el-form-item__content .el-input .el-input__inner {
  line-height: 30px;
  height: 30px;
}
</style>
