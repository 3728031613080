<template>
  <el-dialog title="修改部门用户信息" :visible.sync="centerDialogVisible" @close="close" width="21%" center>
    <el-form :model="ruleForm" ref="ruleForm" label-width="120px" :inline="true" class="demo-ruleForm">
      <!-- <el-form-item label="部门ID" prop="dept_id">
        <el-input v-model="ruleForm.dept_id"></el-input>
      </el-form-item> -->
      <el-form-item label="用户名：" prop="name">
        <span>{{ruleForm.name}}</span>
        <!-- <el-input v-model="ruleForm.name"></el-input> -->
      </el-form-item>
      <el-form-item label="职位：" prop="position">
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in position" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'deptuseredit',  // 部门设置员工
  data () {
    return {
      centerDialogVisible: false, // 组件是否显示
      ruleForm: {
        dept_id: '',  // 部门ID
        user_id: '',  // 用户ID
        position: '',  // 职位
      },
      value: '',  // 职员职位选中
      position: [
        {
          value: 0,
          label: '普通职员',
        },
        {
          value: 1,
          label: '初级职员',
        },
        {
          value: 2,
          label: '高级职员',
        },
        {
          value: 3,
          label: '副主管',
        },
        {
          value: 4,
          label: '主管',
        },
      ]
    }
  },
  created () {
    // 编辑网站传过来的网站ID
    this.bus.$on('deptuserinfo', data => {
      // console.log('deptuserinfo', data);
      this.ruleForm = data
    })
    // 编辑网站传过来的网站ID
    this.bus.$on('editshow', data => {
      this.centerDialogVisible = data
    })
  },
  methods: {
    // 子组件向父组件传值
    close () {
      // 向父组件传递一个方法 父组件通过操作方法修改值
      this.$emit('childedit')
    },
    // 新增
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.submit({
            url: '/deptuser/edit',
            method: 'POST',
            data: {   // 请求数据
              dept_id: this.ruleForm.dept_id,   // 部门ID
              user_id: this.ruleForm.user_id,   // 用户id
              position: this.value,  // 用户职位
            }
          }).then(res => {
            // 新增部门员工成功
            this.close();
            this.centerDialogVisible = false
            this.$message('修改部门用户成功！');
            console.log("修改部门用户成功！", res)
          }).catch(err => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style>
.el-form .el-form-item {
  margin-right: 30px;
}
</style>