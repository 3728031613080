// 这是用户容器
<template>
  <div>
    <div class="title" style="font-size: 28px; font-weight: bold; margin: 30px auto;">个人信息</div>
    <div class="demo-ruleForm" style="width:950px; margin: 0 auto;">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" :inline="true" class="elform" style="text-align: left;">
        <!-- <el-form-item label="用户ID" prop="user_id">
        <el-input v-model="ruleForm.user_id"></el-input>
      </el-form-item> -->
        <el-form-item label="登录名" prop="login_id">
          <el-input v-model="ruleForm.login_id"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pwdhash">
          <el-input v-model="ruleForm.pwdhash"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkpwdhash">
          <el-input v-model="ruleForm.checkpwdhash"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </span>
    </div>
  </div>

</template>
<script>

export default {
  name: 'usercontainer',  // 用户容器
  data () {
    // 判断两次输入密码是否正确
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkpwdhash !== '') {
          this.$refs.ruleForm.validateField('checkpwdhash');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pwdhash) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      userinfo: {},   // 缓存取到的用户信息
      ruleForm: {
        user_id: '',    // 用户id -- V
        login_id: '',   // 登录名 -- V
        pwdhash: '',   // 登录密码 -- V
        checkpwdhash: '',  // 确认密码
      },
      rules: {
        login_id: [
          { required: true, message: '请输入登录名', trigger: 'blur' },
          { min: 4, message: '长度必须大于4位', trigger: 'blur' }
        ],
        pwdhash: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        checkpwdhash: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
      }
    }
  },
  created () {
    /**
         * 取出存储在缓存中的用户信息
         * JSON.parse(localStorage.getItem('userinfo'))
         */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    // // console.log("用户信息:", this.userinfo);
    this.ruleForm.login_id = userinfo.login_id
    this.ruleForm.user_id = userinfo.user_id

  },
  components: {

  },
  methods: {

    // 新增
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 登录密码
          let pwdhash = this.$md5(this.ruleForm.login_id + this.ruleForm.pwdhash);
          this.$http.submit({
            url: '/user/editlogin',
            method: 'POST',
            data: {   // 请求数据
              user_id: this.ruleForm.user_id,   // 用户ID
              login_id: this.ruleForm.login_id,   // 登录名
              pwdhash: pwdhash,  // 登录密码
            }
          }).then(res => {
            // 新增用户成功
            this.$message('修改用户登录名或密码成功');
            console.log("修改用户登录名或密码成功!", res);
          }).catch(err => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style>
</style>