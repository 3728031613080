// 管理线索手动分配
<template>
  <el-container class="elc">
    <el-main class="elm">
      <!-- 是否显示表格 -->
      <div v-if="setshow">
        <el-header class="elh">
          <!-- 对表格进行筛选 -->
          <el-form :inline="true">
            <el-form-item label="编号：" label-width="60px" style="width:150px; margin-right: 15px; margin-bottom: 0;">
              <el-input v-model="lead_id" style="width:90px;"></el-input>
            </el-form-item>
            <el-form-item label="手机号：" label-width="80px" style=" margin-right: 10px;">
              <el-input v-model="leadmobilevalue" style="width:150px;" maxlength="11" placeholder="手机号" clearable></el-input>
            </el-form-item>
            <el-form-item label="新旧：" label-width="60px" style="width:150px; margin-right: 15px; margin-bottom: 0;">
              <el-select v-model="newoldvalue" placeholder="请选择" style="width:90px;" :clearable="true">
                <el-option v-for="(item, index) in newoldall" :key="index" :label="item.text" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态：" label-width="60px" style="width:150px; margin-right: 15px; margin-bottom: 0;">
              <el-select v-model="fllowvalue" placeholder="请选择" style="width:90px;" :clearable="true">
                <el-option v-for="(item, index) in fllowall" :key="index" :label="item.text" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="部门：" label-width="60px" style="width:180px; margin-right: 15px; margin-bottom: 0;">
              <template>
                <el-cascader v-model="deptvalue" :clearable="true" :options="deptall" :props="defaultProps" @change="deptChange" style="width:120px;"></el-cascader>
              </template>
            </el-form-item>
            <el-form-item label="员工：" label-width="60px" style="width:180px; margin-right: 15px; margin-bottom: 0;">
              <el-select v-model="uservalue" placeholder="请选择" @focus="userevent" style="width:120px;" :clearable="true">
                <el-option v-for="(item, index) in userall" :key="index" :label="item.name" :value="item.user_id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始：" label-width="60px" style="width:200px; margin-right: 15px; margin-bottom: 0;">
              <!-- <el-input v-model="starttime" style="width:120px;"></el-input> -->
              <el-date-picker v-model="starttime" type="date" value-format="yyyy-MM-dd" style="width:140px;" placeholder="选择开始日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束：" label-width="60px" style="width:200px; margin-right: 15px; margin-bottom: 0;">
              <!-- <el-input v-model="endtime" style="width:120px;"></el-input> -->
              <el-date-picker v-model="endtime" type="date" value-format="yyyy-MM-dd" style="width:140px;" placeholder="选择结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item style="width:75px; margin-right: 15px; margin-bottom: 0;">
              <el-button type="primary" size="medium" @click="sub">筛选</el-button>
            </el-form-item>
          </el-form>
        </el-header>
        <!-- 线索表格 -->
        <el-table :data="leadFollowHisList" ref="uncheck" max-height="750" :row-class-name="tableRowClassName" :header-cell-style="{ background: '#333744' }" @selection-change="selectionLineChangeHandle">
          <!-- 表头操作项-分配按钮 -->
          <el-table-column width="50">
            <template slot="header">
              <el-button type="primary" icon="iconfont icon-zhuanfa" @click="forwarding" style="padding: 5px 10px; font-size: 14px"></el-button>
            </template>
          </el-table-column>
          <!-- 多选列 -->
          <el-table-column type="selection" :show-overflow-tooltip="true" width="55"></el-table-column>
          <el-table-column prop="lead_id" :show-overflow-tooltip="true" label="编号" width="120">
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" label="新旧">
            <template slot-scope="scope">
              {{ scope.row.hs == 0 ? '新资源' : '回收资源' }}
            </template>
          </el-table-column>
          <!-- <div v-if="userinfo.dept_id == '12308'">
            <el-table-column :show-overflow-tooltip="true" label="来源">
              <template slot-scope="scope">
                {{ scope.row.path }}
              </template>
            </el-table-column>
          </div> -->
          <el-table-column label="资源进线时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.row.date + ' ' + scope.row.time }}
            </template>
          </el-table-column>
          <el-table-column prop="mask_mobile" :show-overflow-tooltip="true" label="电话">
          </el-table-column>
          <!-- <el-table-column prop="keywords" :show-overflow-tooltip="true" label="关键字">
          </el-table-column> -->
          <el-table-column prop="follow_status" :show-overflow-tooltip="true" label="状态">
          </el-table-column>
          <el-table-column prop="keywords" :show-overflow-tooltip="true" label="部门">
            <template slot-scope="scope">
              {{ scope.row.dept_name }}{{'(' + scope.row.dept_id + ')'}}
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" label="员工">
            <template slot-scope="scope">
              {{ scope.row.user_name == null ? '' : scope.row.user_name }}
              {{ scope.row.user_id==null ? '' : '(' + scope.row.user_id + ')'  }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleset(scope.$index, leadFollowHisList)">
                查看
              </el-button>
            </template>
          </el-table-column>
          <!-- 表未刷新表单操作项 -->
          <el-table-column width="50">
            <template slot="header">
              <el-button type="primary" icon="el-icon-refresh-right" @click="refresh" style="padding: 5px 10px; font-size: 16px"></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
          <!-- 
        size-change 当前条数发送改变时
        current-change 当前页数发生变化时
        current-page 当前页数
       -->
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="total, prev, pager, next, sizes" :total="count" prev-text="上1页" next-text="下1页">
          </el-pagination>
        </div>
      </div>

      <!-- 跟进组件 -->
      <dataset v-else :leadid="leadid" @childset="childset"></dataset>
      <!-- 转发组件 -->
      <forwarding :zfshow="zfshow" :xzar="dataonLineListSelections" @fpcg="fpcg" @childzfshow="childzfshow"></forwarding>
    </el-main>
  </el-container>
</template>
<script>
import dataset from './dataset.vue'   // 跟进组件
import forwarding from './forwarding.vue'   // 转发组件
export default {
  name: 'leadFollowHis',      // 管理线索手动分配
  data() {
    return {
      userinfo: [],     // 当前用户信息
      leadid: '',   // 传递给跟进子组件的资源lead_id
      currentPage: 1,    // 线索当前页数
      pageSize: 20,   // 每页显示条数
      count: 0,    // 线索总条数
      leadFollowHisList: [],    // 当前用户线索数据
      dataonLineListSelections: [],    // 资源选中的数组
      setshow: true,    // 资源跟进组件是否显示
      zfshow: false,     // 转发组件

      deptvalue: '',  // 部门树选中的值
      deptall: [],  // 部门树
      defaultProps: { // 层级选择器值 对拿到的部门树数据做处理
        //可以选中一级内容
        checkStrictly: true,
        //数据源parantList中的cat_id做数据绑定
        value: 'dept_id',
        //数据源parantList的cat_name渲染出来的内容
        label: 'dept_name',
        //数据源parantList的children做嵌套
        children: 'children',
        // 数组选中的值只会打印最后一项
        emitPath: false,
      },
      lead_id: '',    // 根据数据id筛选
      uservalue: '',    // 用户选树中的值
      userall: [],    // 用户树
      fllowvalue: '',   // 跟进状态默认值
      leadmobilevalue: '',    // 线索筛选中的手机号
      fllowall: [
        { text: '未跟进', value: '0' },
        { text: '跟进中', value: '1' },
        { text: '有意向', value: '2' },
        { text: '无意向', value: '3' },
        { text: '成功', value: '4' },
        { text: '部门公海', value: '5' },
      ],
      newoldvalue: '',    // 新旧资源默认值
      newoldall: [    // 筛选资源数组
        { text: '新资源', value: '0' },
        { text: '回收资源', value: '1' },
      ],
      starttime: '',    // 开始时间
      endtime: '',    // 结束时间
      filterArr: {},    // 筛选的值
    }
  },
  components: {
    dataset,  // 跟进组件
    forwarding,   // 转发组件
  },
  created() {
    /**
     * 取出存储在缓存中的用户信息
     * JSON.parse(localStorage.getItem('userinfo'))
     */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // console.log("用户信息:", this.userinfo);
    // 默认的部门
    this.deptvalue = this.userinfo.dept_id;

    // 拿到用作下来选择的部门树
    // 部门树
    this.$http.submit({
      url: '/dept/thistree',
      method: 'POST',
      data: {   // 请求数据
        dept_id: this.userinfo.dept_id
      }
    }).then(res => {
      // 将用户列表赋值到页面
      this.deptall.push(res.data[2]);
    }).catch(err => {
      console.log(err);
    })

    // 加载页面获取当前用户线索信息
    this.$http.submit({
      url: '/follow/mgr/todo',
      method: 'POST',
      data: {   // 请求数据
        page: 1,
        pagesize: 20,
        user_id: this.userinfo.user_id,   // 用户id
      }
    }).then(res => {
      res = res.data[2];
      this.currentPage = res.page;  // 当前页数
      // 将新增进线列表存到未分配进线
      this.leadFollowHisList = res.data;  // 线索数据
      this.count = res.count;   // 总条数

      if (this.leadFollowHisList == '' || this.leadFollowHisList == null || this.leadFollowHisList == []) {
        console.log('你当前没有资源！');
      } else {
        this.leadFollowHisList.forEach(item => {
          if (item.follow_status == 0) {
            item.follow_status = '未跟进'
          } else if (item.follow_status == 1) {
            item.follow_status = '跟进中'
          } else if (item.follow_status == 2) {
            item.follow_status = '有意向'
          } else if (item.follow_status == 3) {
            item.follow_status = '无意向'
          } else if (item.follow_status == 4) {
            item.follow_status = '成功'
          } else if (item.follow_status == 5) {
            item.follow_status = '部门公海'
          }
        })
      }
    }).catch(err => {
      console.log(err);
    })

  },

  methods: {

    /**
     * 状态选中的值 -- this.fllowvalue
     * 部门选中的值 -- this.deptvalue
     * 员工选中的值 -- this.uservalue
     * 开始时间 -- this.starttime
     * 结束时间 -- this.endtime
     */
    // 按照筛选条件筛选
    sub() {
      // console.log('默认的部门：', this.deptvalue);
      // 筛选的条件
      let filters = {
        lead_id: this.lead_id,    // 线索id
        mobile: this.leadmobilevalue,   // 手机号
        follow_status: this.fllowvalue,   // 状态
        dept_id: this.deptvalue,    // 部门
        user_id: this.uservalue,    // 员工
        starttime: this.starttime,    // 开始时间
        endtime: this.endtime,    // 结束时间
        hs: this.newoldvalue,   // 资源新旧
      }
      this.filterArr = filters;   // 记录筛选的值
      this.$http.submit({
        url: '/follow/mgr/todo',
        method: 'POST',
        data: {   // 请求数据
          page: 1,
          pagesize: 20,
          user_id: this.userinfo.user_id,   // 用户id
          filter: this.filterArr,     // 筛选值
        }
      }).then(res => {
        // console.log('res==', res);
        res = res.data[2];
        this.currentPage = res.page;  // 当前页数
        // 将新增进线列表存到未分配进线
        this.leadFollowHisList = res.data;  // 进线数据
        this.count = res.count;   // 总条数
        if (this.leadFollowHisList == '' || this.leadFollowHisList == null || this.leadFollowHisList == []) {
          console.log('没有部门员工');
        } else {
          this.leadFollowHisList.forEach(item => {
            if (item.follow_status == 0) {
              item.follow_status = "未跟进"
            } else if (item.follow_status == 1) {
              item.follow_status = "跟进中"
            } else if (item.follow_status == 2) {
              item.follow_status = "有意向"
            } else if (item.follow_status == 3) {
              item.follow_status = "无意向"
            } else if (item.follow_status == 4) {
              item.follow_status = "成功"
            } else if (item.follow_status == 5) {
              item.follow_status = '部门公海'
            }
          })
        }
      }).catch(err => {
        console.log(err);
      })
    },

    // 部门选中信息修改后
    deptChange(value) {    // 部门选中的值
      // console.log('默认的部门：', value);
      this.deptvalue = value;   // 将选中的部门id赋值给默认值
      this.uservalue = '';
    },
    // 员工选中信息修改后
    userevent() {    // 用户选项打开后执行
      this.$http.submit({
        url: '/deptuser/all',
        method: 'POST',
        data: {   // 请求数据
          dept_id: this.deptvalue,    // 部门选中的值
        }
      }).then(res => {
        // 通过部门id获取员工信息
        this.userall = res.data[2];
      }).catch(err => {
        console.log(err);
      })
    },

    // 资源分配成功后
    fpcg(data) {   // 当手动分配成功后删除分配掉的数据
      console.log('----', data);
      let that = this;
      data.forEach((item) => {
        that.leadFollowHisList.forEach((itemI, indexI) => {
          if (item === itemI.lead_id) {
            that.leadFollowHisList.splice(indexI, 1);
            // console.log(itemI, '====', indexI);
          }
        })
      })
    },

    // 子组件传递过来的方法
    childzfshow() {   // 转发组件传递过来的方法
      this.zfshow = false
    },
    // 子组件传递过来的方法
    childset() {   // 跟进资源子组件传递过来的方法 关闭窗口
      this.setshow = true
      // 跟进成功返回后 刷新列表
      this.$http.submit({
        url: '/follow/mgr/todo',
        method: 'POST',
        data: {   // 请求数据
          page: 1,
          pagesize: 20,
          user_id: this.userinfo.user_id,   // 用户id
          filter: this.filterArr,     // 筛选值
        }
      }).then(res => {
        res = res.data[2];
        this.currentPage = res.page;  // 当前页数
        // 将新增进线列表存到未分配进线
        this.leadFollowHisList = res.data;  // 线索数据
        this.count = res.count;   // 总条数

        if (this.leadFollowHisList == '' || this.leadFollowHisList == null || this.leadFollowHisList == []) {
          console.log('你当前没有资源！');
        } else {
          this.leadFollowHisList.forEach(item => {
            if (item.follow_status == 0) {
              item.follow_status = '未跟进'
            } else if (item.follow_status == 1) {
              item.follow_status = '跟进中'
            } else if (item.follow_status == 2) {
              item.follow_status = '有意向'
            } else if (item.follow_status == 3) {
              item.follow_status = '无意向'
            } else if (item.follow_status == 4) {
              item.follow_status = '成功'
            } else if (item.follow_status == 5) {
              item.follow_status = '部门公海'
            }
          })
        }
      }).catch(err => {
        console.log(err);
      })
    },

    // 选中内容赋值到列表数组
    selectionLineChangeHandle(val) {
      this.dataonLineListSelections = []
      val.forEach(item => {
        this.dataonLineListSelections.push(item.lead_id)
      })
      // console.log('选中的资源数据：', this.dataonLineListSelections);
    },
    // 这是转发按钮
    forwarding() {
      // 选中的数据列表
      var val = this.dataonLineListSelections;
      if (val == '') {
        this.$message({
          message: '请选中要转发的进线',
          type: 'error',
        });
      } else {
        this.zfshow = true
        console.log("转发了线索资源：", this.dataonLineListSelections);
      }
    },

    // 跟进按钮点击触发的事件
    handleset(index, row) {
      this.setshow = false
      this.leadid = row[index].lead_id;   // 跟进按钮提交给子组件的lead_id
      // console.log("跟进按钮点击的线索id：", row[index].lead_id);
    },

    // 刷新表格按钮
    refresh() {
      // 取出当前用户的资源
      this.$http.submit({
        url: '/follow/mgr/todo',
        method: 'POST',
        data: {   // 请求数据
          page: 1,
          pagesize: 20,
          user_id: this.userinfo.user_id,   // 用户id
          filter: this.filterArr,     // 筛选值
        }
      }).then(res => {
        res = res.data[2];
        this.currentPage = res.page;  // 当前页数
        // 将新增进线列表存到未分配进线
        this.leadFollowHisList = res.data;  // 线索数据
        this.count = res.count;   // 总条数

        if (this.leadFollowHisList == '' || this.leadFollowHisList == null || this.leadFollowHisList == []) {
          console.log('你当前没有资源！');
        } else {
          this.leadFollowHisList.forEach(item => {
            if (item.follow_status == 0) {
              item.follow_status = '未跟进'
            } else if (item.follow_status == 1) {
              item.follow_status = '跟进中'
            } else if (item.follow_status == 2) {
              item.follow_status = '有意向'
            } else if (item.follow_status == 3) {
              item.follow_status = '无意向'
            } else if (item.follow_status == 4) {
              item.follow_status = '成功'
            } else if (item.follow_status == 5) {
              item.follow_status = '部门公海'
            }
          })
        }
      }).catch(err => {
        console.log(err);
      })
    },

    // 页面列表显示条数发送变化后
    handleSizeChange: function (pageSize) {
      // console.log('页面条数发生变化', pageSize);
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currentPage);
      this.$http.submit({
        url: '/follow/mgr/todo',
        method: 'POST',
        data: {   // 请求数据
          pagesize: this.pageSize,    // 请求的条数
          user_id: this.userinfo.user_id,   // 用户id
          filter: this.filterArr,     // 筛选值
        }
      }).then(res => {
        // 将新的线索存到表格
        res = res.data[2];
        this.leadFollowHisList = res.data;  // 线索数据
        this.count = res.count;   // 总条数
        if (this.leadFollowHisList == '' || this.leadFollowHisList == null || this.leadFollowHisList == []) {
          console.log('你当前没有资源！');
        } else {
          this.leadFollowHisList.forEach(item => {
            if (item.follow_status == 0) {
              item.follow_status = '未跟进'
            } else if (item.follow_status == 1) {
              item.follow_status = '跟进中'
            } else if (item.follow_status == 2) {
              item.follow_status = '有意向'
            } else if (item.follow_status == 3) {
              item.follow_status = '无意向'
            } else if (item.follow_status == 4) {
              item.follow_status = '成功'
            } else if (item.follow_status == 5) {
              item.follow_status = '部门公海'
            }
          })
        }
      }).catch(err => {
        console.log(err);
      })
    },

    // 列表页数发生变化后
    handleCurrentChange: function (currentPage) {
      // console.log('页数发送变化', currentPage);
      this.currentPage = currentPage;
      this.$http.submit({
        url: '/follow/mgr/todo',
        method: 'POST',
        data: {   // 请求数据
          page: this.currentPage,
          pagesize: this.pageSize,
          user_id: this.userinfo.user_id,   // 用户id
          filter: this.filterArr,     // 筛选值
        }
      }).then(res => {
        // 将新增进线列表存到未分配进线
        res = res.data[2];
        this.currentPage = res.page;  // 当前页
        this.leadFollowHisList = res.data;  // 线索数据
        this.count = res.count;   // 总条数
        if (this.leadFollowHisList == '' || this.leadFollowHisList == null || this.leadFollowHisList == []) {
          console.log('你当前没有资源！');
        } else {
          this.leadFollowHisList.forEach(item => {
            if (item.follow_status == 0) {
              item.follow_status = '未跟进'
            } else if (item.follow_status == 1) {
              item.follow_status = '跟进中'
            } else if (item.follow_status == 2) {
              item.follow_status = '有意向'
            } else if (item.follow_status == 3) {
              item.follow_status = '无意向'
            } else if (item.follow_status == 4) {
              item.follow_status = '成功'
            } else if (item.follow_status == 5) {
              item.follow_status = '部门公海'
            }
          })
        }
      }).catch(err => {
        console.log(err);
      })
    },

    // 斑马线+状态背景色
    tableRowClassName({ row, rowIndex }) {
      // console.log(row, '---', rowIndex);
      let index = rowIndex + 1;
      if (row.follow_status == '未跟进') {
        if (index % 2 == 0) {
          return 'warning-row'
        }
        return
      } else if (row.follow_status == '跟进中') {
        return 'gjz-row'
      } else if (row.follow_status == '有意向') {
        return 'yyx-row'
      } else if (row.follow_status == '无意向') {
        return 'wyx-row'
      } else if (row.follow_status == '成功') {
        return 'cg-row'
      } else if (row.follow_status == '部门公海') {
        return 'bmgh-row'
      }

    },
  }
}
</script>

<style>
.elc .elm .elh {
  /** 将默认父组件的样式取消 */
  height: auto !important;
  line-height: 25px;
  margin-bottom: 5px;
}
.elc .elm .elh .el-form .el-form-item {
  margin-bottom: 15px;
}
.el-container .el-main {
}
.elc {
  /** 将默认父组件的样式取消 */
  padding: 5px 0 !important;
}
.elc .elm {
  /** 将默认父组件的样式取消 */
  padding: 0 !important;
}
/* 斑马线的多种状态 */
.el-table .gjz-row {
  background: #e1d7d7;
}
.el-table .yyx-row {
  background: #a8c7ee;
}
.el-table .wyx-row {
  background: #f2b7b7;
}
.el-table .cg-row {
  background: #b0ea93;
}
.el-table .bmgh-row {
  background: #f7ff00;
}
.el-table__column-filter-trigger {
  line-height: 1;
}
</style>