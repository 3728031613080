// 这是跟进的具体内容
<template>
  <div style="text-align:left;">
    <!-- 关闭跟进弹窗 -->
    <el-button @click="close" type="primary" icon="el-icon-back" style="float:right; margin-right: 0; padding: 5px 10px;"></el-button>
    <!-- 资源数据 -->
    <p style="margin-bottom:20px;">资源数据 <span style="font-size: 14px; color: red; font-weight: bold;"> {{leaddata.hs == 0 ? '新资源' : '回收资源'}} </span></p>
    <el-form ref="leaddata" class="efil" :model="leaddata" label-width="100px" :inline="true">
      <el-form-item label="手机号：">
        <span>{{leaddata.mobile}}</span>
      </el-form-item>
      <!-- <el-form-item label="关键字：">
        <span>{{leaddata.keywords}}</span>
      </el-form-item> -->
      <el-form-item label="进线编号：">
        <span>{{leaddata.lead_id}}</span>
      </el-form-item>
      <el-form-item label="业务员：">
        <span>{{userinfo.name,}}</span>
      </el-form-item>
      <el-form-item label="进线时间：">
        {{ leaddata.date + ' ' + leaddata.time }}
      </el-form-item>
      <!-- 资源状态打标签 单选 -->
      <el-form-item :label="radio.label + '：'">
        <el-radio class="radios" v-for="(item, index) in radio.values" :key="index" v-model="radiovalue" :value="radiovalue === null ? item.default : radiovalue" :label="index.toString()" @change="labelSub(index, $event)">
          {{item}}
        </el-radio>
      </el-form-item>
    </el-form>
    <!-- 资源跟进 -->
    <p style="margin-bottom:20px;">资源跟进</p>
    <!-- 资源跟进组件 -->
    <formc :leadid="leadid"></formc>
    <!-- 跟进记录 -->
    <div>
      <el-timeline>
        <!-- <el-timeline-item timestamp="备注" placement="top">
          <el-card>
            <el-form :model="note" class="efil" :rules="rules" label-width="80px" ref="note">
              <el-form-item label="标题" prop="title">
                <el-input v-model="note.title"></el-input>
              </el-form-item>
              <el-form-item label="内容" prop="content" style="margin-top:25px;">
                <el-input type="textarea" v-model="note.content"></el-input>
              </el-form-item>
              <el-form-item style="float: right;">
                <el-button type="primary" @click="submitForm">提交</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </el-timeline-item> -->
        <el-timeline-item timestamp="历史记录" placement="top">
          <el-card>
            <!-- {{jl}} -->
            <el-table :data="jl" style="width: 100%" :header-cell-style="{ background: '#333744' }">
              <el-table-column prop="dt" label="时间" width="200">
              </el-table-column>
              <el-table-column label="提交人" width="180">
                <template slot-scope="scope">
                  {{ scope.row.name + '(' + scope.row.user_id+')' }}
                </template>
              </el-table-column>
              <el-table-column label="日志" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span style="font-weight: bold;">{{ scope.row.title+ '：' }}</span>{{ scope.row.content }}
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>
<script>
import formc from './formc.vue'    // 资源跟进表单

export default {
  name: 'leadFollowHisDataset',
  props: {    // 父组件传递过来的数据
    leadid: {
      type: String
    },
  },
  data () {
    return {
      userinfo: [],   // 当前用户信息
      leaddata: {},  // 资源数据
      radiovalue: '',   // 单选框默认值
      radio: {
        default: '',
        field: 'follow_status',
        label: '状态',
        values: ["未填写", "跟进中", "有意向", "无意向", "成功", "部门公海"]
      },
      note: {},   // 备注信息
      jl: [],   // 历史记录
      rules: {
        /**
         * required 是否必填
         */
        title: [{ required: true, message: '请输入提交的标题', trigger: 'blur' }],
      },
    }
  },
  components: {
    formc,    // 资源跟进表单组件
  },
  created () {
    /**
     * 取出存储在缓存中的用户信息
     * JSON.parse(localStorage.getItem('userinfo'))
     */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // console.log("用户信息:", this.userinfo);

    // 获取某条跟进数据
    this.$http.submit({
      url: '/follow/data/base',
      method: 'POST',
      data: {   // 请求数据
        user_id: this.userinfo.user_id,
        lead_id: this.leadid
      }
    }).then(res => {
      // 数据
      // console.log('跟进详情', res);
      this.leaddata = res.data[2];
    }).catch(err => {
      console.log(err);
    })

    // 跟进状态的默认值
    this.$http.submit({
      url: '/follow/prop/get',
      method: 'POST',
      data: {   // 请求数据
        lead_id: this.leadid, // form类型
        field: "follow_status"
      }
    }).then(res => {
      // 数据
      let rows = res.data[2];
      this.radiovalue = rows.follow_status;   // 默认值
    }).catch(err => {
      console.log(err);
    })

    // 获取跟进记录
    // 获取历史记录
    this.$http.submit({
      url: '/follow/log/all',
      method: 'POST',
      data: {   // 请求数据
        lead_id: this.leadid
      }
    }).then(res => {
      // 数据
      // console.log('历史记录', res);
      this.jl = res.data[2];
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    close () { // 关闭跟进窗口 
      this.$emit('childset')
    },

    // 对资源打标签
    labelSub (index) {
      // console.log(index, '===', $event);
      this.$http.submit({
        url: '/follow/prop/set',
        method: 'POST',
        data: {   // 请求数据
          lead_id: this.leadid,  // 进线ID
          field: "follow_status",
          value: index,
        }
      }).then(res => {
        // 数据
        console.log('资源打标签跟进结果', res);
      }).catch(err => {
        console.log(err);
      })
    },

    // 提交备注信息
    submitForm () {
      this.$refs.note.validate((valid) => {
        if (valid) {
          // 跟进
          this.$http.submit({
            url: '/follow/log/add',
            method: 'POST',
            data: {   // 请求数据
              user_id: this.userinfo.user_id,   // 员工id
              lead_id: this.leadid,   // 资源id
              title: this.note.title,   // 备注标题
              content: this.note.content,   // 备注内容
            }
          }).then(res => {
            // 备注成功后清空输入框内容
            this.note = {}
            // 备注成功后 通过资源id获取提交的数据
            this.$http.submit({
              url: '/follow/log/all',
              method: 'POST',
              data: {   // 请求数据
                lead_id: this.leadid
              }
            }).then(res => {
              // 数据
              this.jl = res.data[2];
            }).catch(err => {
              console.log(err);
            })
            this.$message('备注成功！');
            console.log("备注成功！", res)
          }).catch(err => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }

  }
}
</script>
<style>
.el-form-item .radios {
  /* 当查看详情时  单选框为不可选中状态 - 只读 */
  pointer-events: none;
}
</style>