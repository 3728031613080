// 资源容器
<template>
  <el-tabs class="tab" v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="所有进线" name="listall">
      <listall :key="timer1"  v-if="activeName == 'listall'"></listall>
    </el-tab-pane>
    <el-tab-pane label="未分配进线" name="listtodo">
      <listtodo :key="timer2" v-if="activeName == 'listtodo'"></listtodo>
    </el-tab-pane>
    <!-- <el-tab-pane label="进线分配" name="dispatch">
    </el-tab-pane> -->
    <el-tab-pane label="已分配进线" name="listdone">
      <listdone :key="timer3" v-if="activeName == 'listdone'"></listdone>
    </el-tab-pane>
    <!-- <el-tab-pane label="我的资源" name="listbyuser">
      <listbyuser :key="timer"></listbyuser>
    </el-tab-pane> -->
  </el-tabs>
</template>
<script>
import listall from './list/all.vue'    // 所有进线
import listtodo from './list/listtodo.vue'   // 未分配资源
import listdone from './list/listdone.vue'   // 已分配资源
// import listbyuser from './follow/list.vue'   // 我的资源
export default {
  name: 'lead',   // 资源容器
  data() {
    return {
      activeName: 'listall',  // 默认选中的选项卡name
      timer: '', // 点击部门列表时更新组件
      // el-tabs 点击标签单独更新组件
      timer1: '',
      timer2: '',
      timer3: '',

    }
  },
  components: {
    listall,    // 所有进线
    listtodo,   // 未分配资源
    listdone,   // 已分配资源
    // listbyuser, // 我的资源
     
  },
  methods: {
    handleClick(tab) {
      // console.log(this.activeName);
      // this.timer = new Date().getTime()
      /**
       * 点击标签页对应刷新
       */
      let name = tab.name;
      if (name == 'listall') {
        this.timer1 = new Date().getTime()
      } else if (name == 'listtodo') {
        this.timer2 = new Date().getTime()
      } else if (name == 'listdone') {
        this.timer3 = new Date().getTime()
      }
    }
  }
}
</script>
