// 线索自动分配
<template>
  <div style='position: relative;'>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="今天计划" name="autotoday">
        <autotoday :key="timer1" v-if="activeName == 'autotoday'"></autotoday>
      </el-tab-pane>
      <el-tab-pane label="明天计划" name="autotomorrow">
        <autotomorrow :key="timer2" v-if="activeName == 'autotomorrow'"></autotomorrow>
      </el-tab-pane>
      <el-tab-pane label="后天计划" name="autoht">
        <autoht :key="timer3" v-if="activeName == 'autoht'"></autoht>
      </el-tab-pane>
    </el-tabs>
    <el-button type="primary" size="medium" style="position: absolute;right:10px;top:2px;" @click="perform">执行一次自动分配</el-button>
  </div>
</template>
<script>
import autotoday from '../rule/topromotejt.vue'    // 推广部门 - 今日计划
import autotomorrow from '../rule/topromotemt.vue'    // 推广部门 - 明日计划
import autoht from '../rule/topromoteht.vue'    // 推广部门 - 后天计划
export default {
  name: 'leadAutoRule',     // 线索自带分配
  data () {
    return {
      activeName: 'autotoday',  // 默认选中的选项卡name
      // el-tabs 点击规则时更新组件
      timer1: '',
      timer2: '',
      timer3: '',
      userinfo: [],   // 获取当前用户信息
    }
  },
  components: {
    autotoday,     // 推广部门 - 今日计划
    autotomorrow,    // 推广部门 - 明日计划
    autoht,    // 推广部门 - 后天计划
  },
  created () {
    /**
         * 取出存储在缓存中的用户信息
         * JSON.parse(localStorage.getItem('userinfo'))
         */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // console.log("用户信息:", this.userinfo);
  },
  methods: {
    handleClick (tab) {  // 切换后刷新
      // console.log("点击了", tab.name);
      /**
       * 点击标签页对应刷新
       */
      let name = tab.name;
      if (name == 'autotoday') {
        this.timer1 = new Date().getTime()
      } else if (name == 'autotomorrow') {
        this.timer2 = new Date().getTime()
      } else if (name == 'autoht') {
        this.timer3 = new Date().getTime()
      }
    },
    perform () {
      // console.log('执行了执行一次自动分配');
      // 页面加载 获取规则列表信息
      this.$http.submit({
        url: '/lead/dispatch/apply',
        method: 'POST',
        data: {   // 请求数据
          dept_id: this.userinfo.dept_id,
        }
      }).then(res => {
        res
        // console.log('执行了执行一次自动分配：', res);
      }).catch(err => {
        console.log(err);
      })
    }

  }
}
</script>
