// 未分配资源
<template>
  <div>
    <el-table :data="listtodo" ref="uncheck" :row-class-name="tableRowClassName" style="width: 100%" max-height="750" :header-cell-style="{ background: '#333744' }" @selection-change="selectionLineChangeHandle">
      <!-- 表头操作项 -->
      <el-table-column width="50">
        <template slot="header">
          <el-button type="primary" icon="iconfont icon-zhuanfa" @click="forwarding" style="padding: 5px 10px; font-size: 14px"></el-button>
        </template>
      </el-table-column>
      <!-- 多选列 -->
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="lead_id" label="编号" width="120">
      </el-table-column>
      <el-table-column label="资源进线时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.date + ' ' + scope.row.time }}
        </template>
      </el-table-column>
      <el-table-column prop="mask_mobile" :show-overflow-tooltip="true" label="电话">
      </el-table-column>
      <!-- <el-table-column prop="keywords" :show-overflow-tooltip="true" label="关键字">
      </el-table-column> -->
      <!-- <el-table-column prop="path" :show-overflow-tooltip="true" label="来源">
      </el-table-column> -->
      <!-- <el-table-column prop="account" :show-overflow-tooltip="true" label="账户">
      </el-table-column> -->
      <!-- <el-table-column prop="ip" :show-overflow-tooltip="true" label="ip">
      </el-table-column> -->
      <!-- <el-table-column prop="ip_zone" :show-overflow-tooltip="true" label="ip归属地">
      </el-table-column> -->
      <!-- <el-table-column prop="valid" :show-overflow-tooltip="true" label="性质">
      </el-table-column> -->
      <!-- <el-table-column prop="dept_id" :show-overflow-tooltip="true" label="部门ID">
      </el-table-column> -->
      <!-- <el-table-column prop="user_id" :show-overflow-tooltip="true" label="用户ID">
      </el-table-column> -->
      <!-- <el-table-column prop="username" label="姓名">
      </el-table-column> -->

    </el-table>
    <!-- 分页 -->
    <div class="paging">
      <!-- 
        size-change 当前条数发送改变时
        current-change 当前页数发生变化时
        current-page 当前页数
       -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="total, prev, pager, next, sizes" :total="count" prev-text="上1页" next-text="下1页">
      </el-pagination>
    </div>
    <!-- 手动分配 -->
    <dispatch :zfshow="zfshow" :xzar="dataonLineListSelections" @fpcg="fpcg" @childzfshow="childzfshow"></dispatch>
  </div>
</template>
<script>
import dispatch from './dispatch.vue'    // 手动分配
export default {
  name: 'listtodo',   // 为分配资源
  data () {
    return {
      currentPage: 1,  // 当前页
      pageSize: 20,   // 每页显示条数
      count: 0,    // 总个数
      listtodo: [],   // 数据
      dataonLineListSelections: [], // 选中数据行列表
      zfshow: false,   // 转发弹窗
    }
  },
  components: {
    dispatch,   // 手动分配
  },
  created () {
    this.$http.submit({
      url: '/lead/pool/todo',
      method: 'POST',
      data: {   // 请求数据
        page: this.currentPage,   // 当前显示页数
        pagesize: this.pageSize,  // 每页显示条数   
      }
    }).then(res => {
      // 数据
      // console.log(res);
      res = res.data[2];
      this.currentPage = res.page;  // 当前页数
      // 将新增进线列表存到未分配进线
      this.listtodo = res.data;  // 进线数据
      this.count = res.count;   // 总条数
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    tableRowClassName ({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return 'warning-row'
      }
    },
    fpcg (data) {   // 当手动分配成功后删除分配掉的数据
      // console.log('分配成功返回了', data);
      let that = this;
      data.forEach((item) => {
        that.listtodo.forEach((itemI, indexI) => {
          if (item === itemI.lead_id) {
            that.listtodo.splice(indexI, 1);
            // console.log(itemI, '====', indexI);
          }
        })
      })
    },
    // 子组件传递过来的方法
    childzfshow () {   // 新增用户子组件传递过来的方法
      this.zfshow = false
    },

    // 选中内容赋值到列表数组
    selectionLineChangeHandle (val) {
      this.dataonLineListSelections = []
      val.forEach(item => {
        this.dataonLineListSelections.push(item.lead_id)
      })
      // console.log('选中的资源数据：', this.dataonLineListSelections);
    },
    // 转发
    forwarding () {
      // 选中的数据列表
      var val = this.dataonLineListSelections;
      if (val == '') {
        this.$message({
          message: '请选中要转发的进线',
          type: 'error',
        });
      } else {
        this.zfshow = true
        // console.log("转发了线索资源：", this.dataonLineListSelections);
      }
    },

    // 页面列表显示条数发送变化后
    handleSizeChange: function (pageSize) {
      // console.log('页面条数发生变化');
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currentPage);
      this.$http.submit({
        url: '/lead/pool/todo',
        method: 'POST',
        data: {   // 请求数据
          pagesize: this.pageSize
        }
      }).then(res => {
        // 将新增进线列表存到未分配进线
        res = res.data[2];
        this.listtodo = res.data;  // 进线数据
        this.count = res.count;   // 总条数
      }).catch(err => {
        console.log(err);
      })
    },
    // 列表页数发生变化后
    handleCurrentChange: function (currentPage) {
      // console.log('页数发送变化');
      // 当前页数改变时 将页数值赋值到页面
      this.currentPage = currentPage;
      this.$http.submit({
        url: '/lead/pool/todo',
        method: 'POST',
        data: {   // 请求数据
          page: this.currentPage,
          pagesize: this.pageSize
        }
      }).then(res => {
        // 将新增进线列表存到未分配进线
        res = res.data[2];
        this.currentPage = res.page;  // 当前页
        this.listtodo = res.data;  // 进线数据
        this.count = res.count;   // 总条数
      }).catch(err => {
        console.log(err);
      })
    },
  }
}
</script>