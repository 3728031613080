// 测试账号
<template>
  <div>
    <div class="title" style="font-size: 20px; font-weight: bold; text-align: initial; margin: 20px auto;">新增白名单</div>
    <div class="demo-ruleForm">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" :inline="true" class="elform" style="text-align: left;">
        <el-form-item label="姓名：" label-width="80px" style=" margin-right: 15px; margin-bottom: 0;">
          <el-input v-model="ruleForm.user_name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" label-width="80px" style="width:230px; margin-right: 15px; margin-bottom: 0;">
          <el-input v-model="ruleForm.mobile" placeholder="请输入测试手机号" style="width:150px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="title" style="font-size: 20px; font-weight: bold; text-align: initial; margin: 20px auto;">白名单记录</div>
    <el-table :data="testMobile" style="width: 100%" max-height="350" :header-cell-style="{ background: '#333744' }">
      <el-table-column prop="id" :show-overflow-tooltip="true" label="id" width="100">
      </el-table-column>
      <el-table-column prop="user_name" :show-overflow-tooltip="true" label="姓名" width="120">
      </el-table-column>
      <el-table-column prop="mobile" :show-overflow-tooltip="true" label="手机号" width="120">
      </el-table-column>
      <el-table-column prop="ctime" :show-overflow-tooltip="true" label="添加时间" width="180">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, testMobile)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "leadPeerReport",
  data () {
    return {
      ruleForm: {},   // 提交测试数据
      testMobile: [],   // 测试手机号记录
    }
  },
  created () {

    // 测试手机号记录记录
    this.$http.submit({
      url: '/testing/mobile/list',
      method: 'POST',
      data: {   // 请求数据
      }
    }).then(res => {
      this.testMobile = res.data[2];    // 测试手机号记录
    }).catch(err => {
      console.log(err);
    })

  },
  methods: {

    // 删除测试手机号
    handleDelete (index, row) {
      console.log('删除', row[index].id);
      this.$http.submit({
        url: '/testing/mobile/remove',
        method: 'POST',
        data: {   // 请求数据
          id: row[index].id,    // 根据id删除
        }
      }).then(res => {
        if (res.data[0] == 0) {
          this.$message('手机号删除成功');
          // 删除成功后刷新一下数据
          this.$http.submit({
            url: '/testing/mobile/list',
            method: 'POST',
            data: {   // 请求数据
            }
          }).then(res => {
            this.testMobile = res.data[2];    // 测试手机号记录
          }).catch(err => {
            console.log(err);
          })
        } else {
          this.$message('手机号删除失败');
        }
      }).catch(err => {
        console.log(err);
      })
    },

    // 添加测试手机号
    onSubmit () {
      this.$http.submit({
        url: '/testing/mobile/add',
        method: 'POST',
        data: {   // 请求数据
          user_name: this.ruleForm.user_name,   // 测试名
          mobile: this.ruleForm.mobile,   // 测试手机号
        }
      }).then(res => {
        if (res.data[0] == 0) {
          this.$message('手机号添加成功');
          // 测试手机号记录
          this.$http.submit({
            url: '/testing/mobile/list',
            method: 'POST',
            data: {   // 请求数据
            }
          }).then(res => {
            console.log('测试手机号记录', res.data[2]);
            this.testMobile = res.data[2];    // 测试手机号记录
          }).catch(err => {
            console.log(err);
          })
        } else {
          this.$message(res.data[1]);
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }
}
</script>
<style>
.jg table {
  border: 1px solid #ccc;
  border-spacing: 0px;
  border-collapse: collapse;
}

.jg td,
.jg th {
  padding: 10px 20px;
  border: 1px solid #ccc;
}
.jg tr td:last-child {
  text-align: right;
}
</style>