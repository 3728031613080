// 列出所有员工
<template>
  <div>
    <!-- <el-table :data="userlist.slice((currentPage - 1) * pageSize, currentPage*pageSize)" style="width: 100%" max-height="750" :header-cell-style="{ background: '#333744' }"> -->
    <el-table :data="userlist" style="width: 100%" max-height="750" :header-cell-style="{ background: '#333744' }">
      <el-table-column prop="user_id" :show-overflow-tooltip="true" label="用户id" width="80">
      </el-table-column>
      <el-table-column prop="user_status" :show-overflow-tooltip="true" label="用户状态" width="80">
      </el-table-column>
      <el-table-column prop="name" :show-overflow-tooltip="true" label="姓名">
      </el-table-column>
      <el-table-column prop="gender" :show-overflow-tooltip="true" label="性别">
      </el-table-column>
      <el-table-column prop="login_id" :show-overflow-tooltip="true" label="登录名">
      </el-table-column>
      <el-table-column prop="mobile" :show-overflow-tooltip="true" label="手机号">
      </el-table-column>
      <!-- <el-table-column prop="email" :show-overflow-tooltip="true" label="email" width="200">
      </el-table-column> -->
      <el-table-column prop="wxid" :show-overflow-tooltip="true" label="微信号">
      </el-table-column>
      <!-- <el-table-column prop="idnum" :show-overflow-tooltip="true" label="身份证号" width="180">
      </el-table-column> -->
      <!-- <el-table-column prop="created_at" label="创建时间">
      </el-table-column> -->
      <el-table-column prop="updated_at" :show-overflow-tooltip="true" label="更新时间">
      </el-table-column>

      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.$index, userlist)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, userlist)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 
        size-change 当前条数发送改变时
        current-change 当前页数发生变化时
        current-page 当前页数
       -->
    <!-- <div class="paging">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="20" layout="total, prev, pager, next, sizes" :total="userlist.length" prev-text="上1页" next-text="下1页">
      </el-pagination>
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'userall',    // 所有员工
  data () {
    return {
      currentPage: 1, // 列表默认显示第几页
      pageSize: 20, // 列表默认显示条数
      userlist: [], // 页面显示列表
    }
  },
  created () {
    // 用户查询传递过来的值
    this.bus.$on('findby', data => {
      this.userlist = data
    })
    // 页面加载就获取数据
    this.$http.submit({
      url: '/user/all',
      method: 'POST',
      data: {   // 请求数据
        pagesize: 1000,
        page: 1
      }
    }).then(res => {
      // 将用户列表赋值到页面
      // console.log(res);
      this.userlist = res.data[2];
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    handleEdit (index, row) {
      console.log('编辑', row[index]);

      // 将修改的部门信息传递给兄弟组件-修改部门

      this.bus.$emit('loginuserygshow', true)
      this.bus.$emit('loginuseryginfo', row[index])

    },
    // 删除按钮 点击删除用户
    handleDelete (index, row) {
      console.log('删除', row[index].user_id);
      this.$http.submit({
        url: '/user/remove',
        method: 'POST',
        data: {   // 请求数据
          user_id: row[index].user_id,   // 用户ID
        }
      }).then(res => {
        // 删除用户成功
        row.splice(index, 1);
        this.$message('"删除用户成功!"');
        console.log("删除用户成功!", res);
      }).catch(err => {
        console.log(err);
      })
    },
    // 页面列表显示条数发送变化后
    handleSizeChange: function (pageSize) {
      // console.log('页面条数发生变化');
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currentPage);
    },
    // 列表页数发生变化后
    handleCurrentChange: function (currentPage) {
      // console.log('页数发送变化');
      // 当前页数改变时 将页数值赋值到页面
      this.currentPage = currentPage;
    },
  }
}
</script>
<style>
.paging {
  position: relative;
  margin-top: 20px;
  float: right;
}
/* 表格内容字体大小 */
.el-table__body-wrapper {
  font-size: 12px;
}
/* 行间距 */
.el-table td,
.el-table th {
  padding: 5px 0 !important;
}
/* 固定列文字大小 */
.el-table--enable-row-transition .el-table__body td {
  font-size: 12px;
}
.paging {
  position: relative;
  margin-top: 20px;
  float: right;
}
.el-table__body-wrapper {
  height: 780px;
}
</style>