// 查找员工
<template>
  <el-dialog title="用户查询" :visible.sync="centerDialogVisible" @close="close" width="35%" center>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" :inline="true" class="demo-ruleForm">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="登录名" prop="login_id">
        <el-input v-model="ruleForm.login_id"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="ruleForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="email" prop="email">
        <el-input v-model="ruleForm.email"></el-input>
      </el-form-item>
      <el-form-item label="微信号" prop="wxid">
        <el-input v-model="ruleForm.wxid"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idnum">
        <el-input v-model="ruleForm.idnum"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">用户查询</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'userfind',   // 查找员工
  props: {  // 获取父组件传递过来的值
    findshow: {
      type: Boolean,
    },
  },
  watch: {
    // 监听父组件传递值的变化 赋值给子组件
    findshow (n) {
      this.centerDialogVisible = n;
    }
  },
  data () {
    return {
      centerDialogVisible: false, // 组件是否显示
      ruleForm: {
        name: '',   // 姓名
        login_id: '',  // 登录名
        mobile: '',   // 手机号
        email: '',    // 邮箱
        wxid: '',   // 微信号
        idnum: '',    // 身份证号
      },
      rules: {

      }
    }
  },
  methods: {
    // 子组件向父组件传值
    close () {
      // 向父组件传递一个方法 父组件通过操作方法修改值
      this.$emit('childfind')
    },
    // 用户查询
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.submit({
            url: '/user/find',
            method: 'POST',
            data: {   // 请求数据
              name: this.ruleForm.name,   // 姓名
              login_id: this.ruleForm.login_id, // 登录名
              mobile: this.ruleForm.mobile,   // 手机号
              email: this.ruleForm.email,   // 邮箱
              wxid: this.ruleForm.wxid,   // 微信号
              idnum: this.ruleForm.idnum  // 身份证号
            }
          }).then(res => {
            // 用户查询成功后关闭查询窗口
            this.close()
            // console.log(res.data[2]);
            // 将查询的值传递给兄弟组件-用户列表
            this.bus.$emit('findby', res.data[2])
            this.$message('用户查询成功');
            console.log('用户查询成功', res);
          }).catch(err => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style>
.el-form .el-form-item {
  margin-right: 30px;
}
</style>