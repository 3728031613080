// 业务 - 个人报表
<template>
  <div>
    <div class="title" style="font-size: 28px; font-weight: bold; margin: 30px auto;">个人报表</div>
    <div class="demo-ruleForm" style="width:950px; margin: 0 auto;">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" :inline="true" class="elform" style="text-align: left;">
        <el-form-item label="开始时间">
          <el-input v-model="ruleForm.starttime"></el-input>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-input v-model="ruleForm.endtime"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <div v-html="jg" class="jg">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'reportMy',     // 个人报表
  data () {
    return {
      isshow: true,
      userinfo: [],   // 用户信息
      ruleForm: {
        starttime: '',
        endtime: '',
      },
      jg: ''
    }
  },
  created () {
    /**
         * 取出存储在缓存中的用户信息
         * JSON.parse(localStorage.getItem('userinfo'))
         */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // console.log("用户信息:", this.userinfo);

    // 获取今天 + 明天日期
    let today = new Date(); // 今天
    let jtYear = today.getFullYear(); // 得到年份
    let jtMonth = today.getMonth() + 1; // 得到月份
    if (jtMonth < 10) jtMonth = "0" + jtMonth;
    let jtDate = today.getDate();
    if (jtDate < 10) jtDate = "0" + jtDate;
    let starttime = jtYear + '-' + jtMonth + '-' + jtDate;
    // console.log('今天', jtYear + '-' + jtMonth + '-' + jtDate);

    let tomorrow = new Date(today.valueOf() + 86400000); // 明天
    let mtYear = tomorrow.getFullYear(); // 得到年份
    let mtMonth = tomorrow.getMonth() + 1; // 得到月份
    if (mtMonth < 10) mtMonth = "0" + mtMonth;
    let mtDate = tomorrow.getDate();
    if (mtDate < 10) mtDate = "0" + mtDate;
    let endtime = mtYear + '-' + mtMonth + '-' + mtDate;
    this.ruleForm.starttime = starttime;
    this.ruleForm.endtime = endtime;

  },
  methods: {
    onSubmit () {
      // console.log(2);
      this.$http.submit({
        url: '/follow/user/report',
        method: 'POST',
        data: {   // 请求数据
          user_id: this.userinfo.userinfo,    // 用户id
          starttime: this.ruleForm.starttime,   // 开始时间
          endtime: this.ruleForm.endtime,   // 结束时间
        }
      }).then(res => {
        console.log(res);
        this.jg = res.data[2]
        // 新增用户成功
        this.$message('报表查看成功');
        console.log("报表查看成功!", res)
      }).catch(err => {
        console.log(err);
      })
    }
  }
}
</script>
<style>
.report {
  font-size: 20px;
  margin-bottom: 15px;
}
.jg table {
  border: 1px solid #ccc;
  border-spacing: 0px;
  border-collapse: collapse;
}

.jg td,
.jg th {
  padding: 10px 20px;
  border: 1px solid #ccc;
}
.jg tr td:last-child {
  text-align: right;
}
</style>