// 这是用户容器
<template>
  <div>
    <div class="title" style="font-size: 28px; font-weight: bold; margin: 30px auto;">个人信息 <el-button type="primary" size="mini" @click="isshow = !isshow">
        {{isshow == false ? '编辑' : '取消'}}
      </el-button>
    </div>
    <div v-if="isshow" class="demo-ruleForm" style="width:950px; margin: 0 auto;">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" :inline="true" class="elform" style="text-align: left;">
        <el-form-item label="姓名：" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="性别：" prop="gender">
          <el-radio v-model="radio" label="1">男</el-radio>
          <el-radio v-model="radio" label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model="ruleForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="微信号：" prop="wxid">
          <el-input v-model="ruleForm.wxid"></el-input>
        </el-form-item>
        <el-form-item label="email：" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item label="身份证号：" prop="idnum">
          <el-input v-model="ruleForm.idnum"></el-input>
        </el-form-item>
        <el-form-item label="生日：" prop="birthday">
          <el-input v-model="ruleForm.birthday"></el-input>
        </el-form-item>
        <el-form-item label="家庭地址：" prop="home_address">
          <el-input v-model="ruleForm.home_address"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="phone">
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="分机：" prop="ext">
          <el-input v-model="ruleForm.ext"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </span>
    </div>
    <div v-else class="demo-ruleForm" style="width:930px; margin: 0 auto;">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" :inline="true" class="elform" style="text-align: left;">
        <el-form-item label="姓名：" prop="name" style="width:200px">
          <span>{{ruleForm.name}}</span>
        </el-form-item>
        <el-form-item label="性别：" prop="gender" style="width:200px">
          <span>{{ruleForm.gender}}</span>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile" style="width:200px">
          <span>{{ruleForm.mobile}}</span>
        </el-form-item>
        <el-form-item label="微信号：" prop="wxid" style="width:200px">
          <span>{{ruleForm.wxid}}</span>
        </el-form-item>
        <el-form-item label="email：" prop="email" style="width:200px">
          <span>{{ruleForm.email}}</span>
        </el-form-item>
        <el-form-item label="身份证号：" prop="idnum" style="width:200px">
          <span>{{ruleForm.idnum}}</span>
        </el-form-item>
        <el-form-item label="生日：" prop="birthday" style="width:200px">
          <span>{{ruleForm.birthday}}</span>
        </el-form-item>
        <el-form-item label="家庭地址：" prop="home_address" style="width:200px">
          <span>{{ruleForm.home_address}}</span>
        </el-form-item>
        <el-form-item label="联系电话：" prop="phone" style="width:200px">
          <span>{{ruleForm.phone}}</span>
        </el-form-item>
        <el-form-item label="分机：" prop="ext" style="width:200px">
          <span>{{ruleForm.ext}}</span>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>
<script>

export default {
  name: 'usercontainer',  // 用户容器
  data () {
    return {
      radio: '',    // 性别选择
      isshow: false,
      userinfo: {},   // 缓存取到的用户信息
      ruleForm: {
      },
      rules: {
      }
    }
  },
  created () {
    /**
         * 取出存储在缓存中的用户信息
         * JSON.parse(localStorage.getItem('userinfo'))
         */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // console.log("用户信息:", this.userinfo);

    this.$http.submit({
      url: '/user/info',
      method: 'POST',
      data: {   // 请求数据
        user_id: this.userinfo.user_id,   // 用户ID
      }
    }).then(res => {
      // 成功后关闭弹窗
      // console.log('--', res.data[2]);
      this.ruleForm = res.data[2]

      if (this.ruleForm.gender == 0) {
        this.radio = '0'
        this.ruleForm.gender = '未设置'
      } else if (this.ruleForm.gender == 1) {
        this.radio = '1'
        this.ruleForm.gender = '男'
      } else if (this.ruleForm.gender == 2) {
        this.radio = '2'
        this.ruleForm.gender = '女'
      }
      // console.log('userinfo', res.data[2]);
    }).catch(err => {
      console.log(err);
    })
    console.log(this.radio);
  },
  components: {

  },
  methods: {
    // 修改用户信息
    submitForm (formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.submit({
            url: '/user/edit',
            method: 'POST',
            data: {   // 请求数据
              user_id: this.userinfo.user_id,   // 用户ID
              name: this.ruleForm.name,   // 姓名
              mobile: this.ruleForm.mobile,  // 手机号
              email: this.ruleForm.email,  // email
              wxid: this.ruleForm.wxid,  // 微信号
              phone: this.ruleForm.phone,  // 联系电话
              ext: this.ruleForm.ext,  // 分机
              gender: this.radio,  // 性别
              idnum: this.ruleForm.idnum,  // 身份证号
              birthday: this.ruleForm.birthday,  // 生日
              home_address: this.ruleForm.home_address,  // 家庭地址
            }
          }).then(res => {
            // 新增用户成功
            this.$message('修改用户信息成功');
            console.log("修改用户信息成功!", res)
          }).catch(err => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style>
</style>