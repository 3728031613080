// 同行列表
<template>
  <div>
    <!-- 同行列表 -->
    <div class="title" style="font-size: 20px; font-weight: bold; text-align: initial; margin: 20px auto;">同行列表 -- 最近15日</div>
    <div class="jg">
      <table>
        <thead>
          <tr>
            <th>线索id</th>
            <th>进线时间</th>
            <th>手机号</th>
            <!-- <th>ip</th> -->
            <!-- <th>ip归属地</th> -->
            <th>负责部门</th>
            <th>负责员工</th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in th" :key="index">
          <tr>
            <td> {{item.lead_id}} </td>
            <td>
              {{item.date + ' ' + item.time}}
            </td>
            <td> {{item.mobile}} </td>
            <!-- <td> {{item.ip}} </td> -->
            <!-- <td> {{item.ip_zone}} </td> -->
            <td>
              {{ item.dept_name + '(' + item.dept_id + ')' }}
            </td>
            <td>
              {{ item.user_name + '(' + item.user_id + ')' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr>
    <!-- 疑似列表 -->
    <div class="title" style="font-size: 20px; font-weight: bold; text-align: initial; margin: 20px auto;">疑似同行列表 -- 最近15日</div>
    <div class="jg">
      <table>
        <thead>
          <tr>
            <th>线索id</th>
            <th>进线时间</th>
            <th>手机号</th>
            <!-- <th>ip</th> -->
            <!-- <th>ip归属地</th> -->
            <th>负责部门</th>
            <th>负责员工</th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in ys" :key="index">
          <tr>
            <td> {{item.lead_id}} </td>
            <td>
              {{item.date + ' ' + item.time}}
            </td>
            <td> {{item.mobile}} </td>
            <!-- <td> {{item.ip}} </td> -->
            <!-- <td> {{item.ip_zone}} </td> -->
            <td>
              {{ item.dept_name + '(' + item.dept_id + ')' }}
            </td>
            <td>
              {{ item.user_name + '(' + item.user_id + ')' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'peerList',
  data () {
    return {
      ys: [],   // 疑似列表
      th: [],   // 同行列表
    }
  },
  created () {
    // 同行记录清单
    this.$http.submit({
      url: '/blocklist/result',
      method: 'POST',
      data: {   // 请求数据
      }
    }).then(res => {
      console.log('同行信息：', res);
      this.th = res.data[2].th;   // 同行信息
      this.ys = res.data[2].ky;   // 疑似同行信息
      // this.$message('同行信息上报成功');
      // console.log("报表查看成功!", res)
    }).catch(err => {
      console.log(err);
    })
  },
}
</script>
<style>
.jg {
  margin-bottom: 20px;
}
.jg table {
  border: 1px solid #ccc;
  border-spacing: 0px;
  border-collapse: collapse;
}

.jg td,
.jg th {
  padding: 10px 20px;
  border: 1px solid #ccc;
}
.jg tr td:last-child {
  text-align: right;
}
</style>