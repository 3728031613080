// 推广页列表
<template>
  <div>
    <!-- <el-table :data="leadpageall.slice((currentPage - 1) * pageSize, currentPage*pageSize)" style="width: 100%" max-height="750" :header-cell-style="{ background: '#333744' }"> -->
    <el-table :data="leadpageall" style="width: 100%" max-height="750" :header-cell-style="{ background: '#333744' }">
      <el-table-column prop="id" :show-overflow-tooltip="true" label="编号" width="120">
      </el-table-column>
      <!-- <el-table-column prop="ctime" :show-overflow-tooltip="true" label="创建时间">
      </el-table-column> -->
      <el-table-column prop="utime" :show-overflow-tooltip="true" label="更新时间">
      </el-table-column>
      <el-table-column prop="page_path" :show-overflow-tooltip="true" label="网站地址">
      </el-table-column>
      <el-table-column prop="page_name" :show-overflow-tooltip="true" label="网站名称">
      </el-table-column>
      <el-table-column prop="page_type" :show-overflow-tooltip="true" label="网站类型">
      </el-table-column>
      <el-table-column prop="channel" :show-overflow-tooltip="true" label="渠道">
      </el-table-column>
      <el-table-column prop="account" :show-overflow-tooltip="true" label="账号">
      </el-table-column>
      <el-table-column label="操作" :show-overflow-tooltip="true" width="160">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.$index, leadpageall)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, leadpageall)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: 'leadpageall',    // 网站列表
  data () {
    return {
      // leadpageall: [], // 网站列表
      leadpageall: []
    }
  },
  created () {
    // 页面加载就获取数据
    this.$http.submit({
      url: '/lead/page/all',
      method: 'POST',
      data: {   // 请求数据

      }
    }).then(res => {
      // 将用户列表赋值到页面
      // console.log('leadpageall', res);
      this.leadpageall = res.data[2];
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    // 删除按钮 点击删除网站
    handleDelete (index, row) {
      console.log('删除', row[index].id);
      this.$http.submit({
        url: '/lead/page/remove',
        method: 'POST',
        data: {   // 请求数据
          id: row[index].id,   // 用户ID
        }
      }).then(res => {
        // 删除用户成功
        row.splice(index, 1);   // 删除一行数据
        this.$message('"删除网站信息成功!"');
        console.log("删除网站信息成功!", res);
      }).catch(err => {
        console.log(err);
      })
    },
    handleEdit (index, row) {
      console.log('编辑', row[index].id);

      // 将编辑的网站ID传递给兄弟组件-编辑网站
      this.bus.$emit('pageidby', row[index])

      this.bus.$emit('editshow', true)
    }
  }
}
</script>
