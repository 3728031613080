<template>
  <el-dialog title="新增部门员工" :visible.sync="centerDialogVisible" @close="close" width="21%" center>
    <el-form :model="ruleForm" ref="ruleForm" label-width="120px" :inline="true" class="demo-ruleForm">
      <el-form-item label="部门：" prop="dept_id">
        <template>
          <el-cascader v-model="value" :options="deptall" :props="defaultProps" @change="handleChange"></el-cascader>
        </template>
      </el-form-item>
      <el-form-item label="用户ID" prop="user_id">
        <!-- <el-input v-model="ruleForm.user_id"></el-input> -->
        <el-select v-model="valueuser" filterable placeholder="请选择" @focus="userevent">
          <el-option v-for="(item, index) in userall" :key="index" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职位：" prop="position">
        <el-select v-model="valuez" placeholder="请选择">
          <el-option v-for="item in position" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'deptuseradd',  // 添加部门用户
  props: {  // 获取父组件传递过来的值
    addshow: {
      type: Boolean,
    },
  },
  watch: {
    // 监听父组件传递值的变化 赋值给子组件
    addshow (n) {
      this.centerDialogVisible = n;
    }

  },
  data () {
    return {
      value: [],  // 部门选中值
      deptall: {},   // 部门列表
      defaultProps: { // 层级选择器值
        //可以选中一级内容
        checkStrictly: true,
        //数据源parantList中的cat_id做数据绑定
        value: 'dept_id',
        //数据源parantList的cat_name渲染出来的内容
        label: 'dept_name',
        //数据源parantList的children做嵌套
        children: 'children'
      },
      deptid: [],   // 选中的部门id
      centerDialogVisible: false, // 组件是否显示
      ruleForm: {
        dept_id: '',  // 部门ID
        user_id: '',  // 用户ID
        position: '',  // 职位
      },
      valuez: '',  // 职员职位选中
      position: [
        {
          value: 0,
          label: '普通职员',
        },
        {
          value: 1,
          label: '初级职员',
        },
        {
          value: 2,
          label: '高级职员',
        },
        {
          value: 3,
          label: '副主管',
        },
        {
          value: 4,
          label: '主管',
        },
      ],

      valueuser: '',  // 职员职位选中
      userall: [],

    }
  },
  created () {
    // 部门树
    this.$http.submit({
      url: '/dept/tree',
      method: 'POST',
      data: {   // 请求数据
      }
    }).then(res => {
      // 将用户列表赋值到页面
      this.deptall = res.data[2];
      // console.log('depttree', res.data[2]);
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    userevent () {
      if (this.value == '') {
        this.useral = []
        this.$message('请先选择部门后选中用户！');
      } else {
        // 获取员工
        this.$http.submit({
          url: '/user/find',
          method: 'POST',
          data: {   // 请求数据
          }
        }).then(res => {
          // 将用户列表赋值到页面
          let us = res.data[2];
          us.forEach(item => {
            let c = {
              value: item.user_id,  // 用户id
              label: item.name,   // 用户姓名
            }
            this.userall.push(c)  // 将用户填充到数组
          })
          // console.log('depttree', res.data[2]);
        }).catch(err => {
          console.log(err);
        })
      }
    },
    handleChange (value) {    // 部门选中的值
      // console.log('~~', value.slice(-1));  // 选中最后一个
      this.deptid = value.slice(-1);
    },

    // 子组件向父组件传值
    close () {
      // 向父组件传递一个方法 父组件通过操作方法修改值
      this.$emit('childadd')
    },
    // 新增
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.submit({
            url: '/deptuser/add',
            method: 'POST',
            data: {   // 请求数据
              dept_id: this.deptid[0],   // 部门ID
              user_id: this.valueuser,   // 用户ID
              position: this.valuez,  // 职位
            }
          }).then(res => {
            // 新增部门员工成功
            this.close();
            this.value = '';
            this.valueuser = '';
            this.valuez = '';
            this.centerDialogVisible = false
            this.$message('新增部门员工成功！');
            console.log("新增部门员工成功！", res)
          }).catch(err => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm (formName) {
      console.log(this.valueuser);
      // console.log(this.valuez);
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style>
.el-form .el-form-item {
  margin-right: 30px;
}
</style>