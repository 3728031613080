<template>
  <el-table :data="deptlist" style="width: 100%" max-height="750" :header-cell-style="{ background: '#333744' }">
    <el-table-column prop="user_id" :show-overflow-tooltip="true" label="员工id">
    </el-table-column>
    <el-table-column prop="name" :show-overflow-tooltip="true" label="员工名">
    </el-table-column>
    <el-table-column prop="position" :show-overflow-tooltip="true" label="员工职务">
    </el-table-column>
    <el-table-column prop="seqnum" :show-overflow-tooltip="true" label="部门显示顺序" width="120">
    </el-table-column>
    <el-table-column prop="parent_id" :show-overflow-tooltip="true" label="上级部门ID">
    </el-table-column>
    <el-table-column prop="leader_id" :show-overflow-tooltip="true" label="部门领导ID">
    </el-table-column>
    <el-table-column prop="leader_name" :show-overflow-tooltip="true" label="部门领导名">
    </el-table-column>
    <el-table-column prop="dept1_id" :show-overflow-tooltip="true" label="一级部门ID">
    </el-table-column>
    <el-table-column prop="dept1_name" :show-overflow-tooltip="true" label="一级部门名">
    </el-table-column>
    <el-table-column prop="dept2_id" :show-overflow-tooltip="true" label="二级部门ID">
    </el-table-column>
    <el-table-column prop="dept2_name" :show-overflow-tooltip="true" label="二级部门名">
    </el-table-column>
    <el-table-column prop="dept3_id" :show-overflow-tooltip="true" label="三级部门ID">
    </el-table-column>
    <el-table-column prop="dept3_name" :show-overflow-tooltip="true" label="三级部门名">
    </el-table-column>
    <el-table-column prop="dept_fullname" :show-overflow-tooltip="true" label="部门全名">
    </el-table-column>
    <el-table-column label="操作" width="160">
      <template slot-scope="scope">
        <el-button size="mini" type="primary" @click="handleEdit(scope.$index, deptlist)">编辑</el-button>
        <el-button size="mini" type="danger" @click="handleDelete(scope.$index, deptlist)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  name: 'deptuserall',  // 列出部门和子部门所有员工
  data () {
    return {
      userinfo: {},   // 获取用户信息
      deptlist: [], // 页面显示列表
    }
  },
  created () {
    /**
    * 取出存储在缓存中的用户信息
    * JSON.parse(localStorage.getItem('userinfo'))
    */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // console.log("用户信息:", this.userinfo);
    // 页面加载就获取数据
    this.$http.submit({
      url: '/deptuser/listall',
      method: 'POST',
      data: {   // 请求数据
        dept_id: this.userinfo.dept_id
      }
    }).then(res => {
      // 将用户列表赋值到页面
      // console.log(res);
      this.deptlist = res.data[2];
      if (this.deptlist == '' || this.deptlist == null || this.deptlist == []) {
        console.log('没有部门员工');
      } else {
        this.deptlist.forEach(item => {
          if (item.position == 0) {
            item.position = '普通职员'
          } else if (item.position == 1) {
            item.position = '初级职员'
          } else if (item.position == 2) {
            item.position = '高级职员'
          } else if (item.position == 3) {
            item.position = '副主管'
          } else if (item.position == 4) {
            item.position = '主管'
          }
        })
      }
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    // 删除按钮 点击删除部门用户
    handleDelete (index, row) {
      // console.log('删除', row[index].user_id);
      this.$http.submit({
        url: '/deptuser/remove',
        method: 'POST',
        data: {   // 请求数据
          dept_id: this.userinfo.dept_id,   // 部门ID
          user_id: row[index].user_id,   // 用户ID
        }
      }).then(res => {
        // 删除部门用户成功
        if(res.data[0] == 0) {
          row.splice(index, 1);
          this.$message('删除部门用户成功！');
        }else {
          // console.log("删除失败");
          this.$message(res.data[1]);
        }
        console.log("删除部门用户成功！", res)
      }).catch(err => {
        console.log(err);
      })
    },
    // 编辑按钮 点击根据用户id修改部门信息
    handleEdit (index, row) {
      console.log('编辑', row[index].user_id);

      // 将编辑的网站ID传递给兄弟组件-编辑网站
      this.bus.$emit('deptuserinfo', row[index])

      this.bus.$emit('editshow', true)
    }
  }
}
</script>