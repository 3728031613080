// 编辑部门
<template>
  <el-dialog title="修改部门信息" :visible.sync="centerDialogVisible" @close="close" width="21%" center>
    <el-form :model="ruleForm" ref="ruleForm" label-width="120px" :inline="true" class="demo-ruleForm">
      <!-- <el-form-item label="部门ID" prop="dept_id">
        <el-input v-model="ruleForm.dept_id"></el-input>
      </el-form-item> -->
      <el-form-item label="部门名" prop="dept_name">
        <el-input v-model="ruleForm.dept_name"></el-input>
      </el-form-item>
      <el-form-item label="上级部门ID" prop="parent_id">
        <el-input v-model="ruleForm.parent_id"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">立即修改</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'deptedit', // 编辑部门
  data () {
    return {
      centerDialogVisible: false, // 组件是否显示
      ruleForm: {
        dept_id: '',  // 部门ID
        dept_name: '',  // 部门名
        parent_id: '',  // 上级部门ID
      },
      cs: {}
    }
  },
  created () {
    // 编辑部门信息按钮传过来的true
    this.bus.$on('editshow', data => {
      this.centerDialogVisible = data
    })
    // 接收编辑按钮传递过来的部门信息
    this.bus.$on('deptinfo', data => {
      this.ruleForm = data
    })
  },
  methods: {
    // 子组件向父组件传值
    close () {
      // 向父组件传递一个方法 父组件通过操作方法修改值
      this.$emit('childedit')
    },
    // 修改部门信息
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.submit({
            url: '/dept/edit',
            method: 'POST',
            data: {   // 请求数据
              dept_id: this.ruleForm.dept_id,   // 部门ID
              dept_name: this.ruleForm.dept_name,   // 部门名
              parent_id: this.ruleForm.parent_id,  // 上级部门ID
            }
          }).then(res => {
            // 修改部门成功
            this.centerDialogVisible = false
            this.close();
            this.$message('修改部门成功！');
            console.log("修改部门成功！", res)
          }).catch(err => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style>
.el-form .el-form-item {
  margin-right: 30px;
}
</style>