// 编辑网站
<template>
  <el-dialog title="修改IP分配" :visible.sync="centerDialogVisible" @close="close" @open="openEvent" width="35%" center>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
      <el-row class="el-row">
        <el-col :span="12" class="title">
          <el-form-item label="IP区域" prop="keyword">
            <el-input v-model="ruleForm.keyword"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="title">
          <el-form-item label="规则备注" prop="notes">
            <el-input v-model="ruleForm.notes"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="title">
          <el-form-item label="部门id" prop="to_dept_id">
            <el-input v-model="ruleForm.to_dept_id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="title">
          <el-form-item label="部门名称" prop="to_dept_name">
            <el-input v-model="ruleForm.to_dept_name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="menu_type == 1" class="title">
          <el-form-item label="员工id" prop="to_user_id">
            <el-input v-model="ruleForm.to_user_id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="menu_type == 1" class="title">
          <el-form-item label="员工名" prop="to_user_name">
            <el-input v-model="ruleForm.to_user_name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="title">
          <el-form-item label="截止时间" prop="end_time">
            <el-date-picker v-model="ruleForm.times" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" :editable="false"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">立即修改</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'leadipedit',     // 编辑网站
  props: {  // 获取父组件传递过来的值
    editshow: {
      type: Boolean,
    },
  },
  watch: {
    // 监听父组件传递值的变化 赋值给子组件
    editshow (n) {
      this.centerDialogVisible = n;
      if(n == true) {
        // 
      }
    }
  },
  data () {
    return {
      ipid: 0,    // 列表组件编辑按钮传递过来的IP ID
      centerDialogVisible: false, // 组件是否显示
      userinfo: [], // 当前用户信息
      menu_type: 0, // 当前用户菜单等级
      ruleForm: {
        keyword: '',   // IP分配区域 -- 上海、广州...
        notes: '',   // IP分配规则备注
        active: 0,  // 是否启用 0-禁用 1-启用
        to_dept_id: '',   // IP分配到的部门ID
        times: [],    // ip分配规则的开始时间和截止时间
      },
      rules: {
        keyword: [
          { type: 'string', required: true, message: '请填写IP区域', trigger: 'change' }
        ],
        to_dept_id: [
          { type: 'string', required: true, message: '请填写部门ID', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    /**
     * 取出存储在缓存中的用户信息
     * JSON.parse(localStorage.getItem('userinfo'))
     */
    let userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.userinfo = userinfo;
    console.log(this.userinfo);
    // 编辑ip规则传过来的ip规则ID
    this.bus.$on('ipid', data => {
      this.ipid = data
      // console.log(data,'====', this.ipid);
    })
    // 编辑ip规则传过来的值
    this.bus.$on('editshow', data => {
      this.centerDialogVisible = data
    })
    // console.log("用户信息:", this.userinfo);
  },
  methods: {
    // 弹窗打开时 编辑加载则获取IP规则基本信息
    openEvent() {
      // 弹窗打开时获取用户信息 设置员工权限是否显示
      this.$http.submit({
        url: '/user/info',
        method: 'POST',
        data: {   // 请求数据
          user_id: this.userinfo.user_id,   // 提交用户登录名
        }
      }).then(res => {
        this.menu_type = res.data[2].menu_type
      })
      .catch((err) => {
        console.log(err);
      });

      this.$http.submit({
        url: "/dispatch/rule/ip/info",
        data: {
          id: this.ipid,
        },
      })
      .then((res) => {
        let [errcode, errmsg, payload] = res.data;
        console.log(errcode, errmsg, payload);
        if (errcode == 0) {
          this.ruleForm = {
            id: payload.id,
            active: payload.active,
            keyword: payload.keyword,
            op_type: payload.op_type,
            to_dept_id: payload.to_dept_id,
            to_dept_name: payload.to_dept_name,
            to_user_id: payload.to_user_id,
            to_user_name: payload.to_user_name,
            notes: payload.notes,
            times:[payload.start_time, payload.end_time],
          };
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    // 子组件向父组件传值
    close () {
      // 向父组件传递一个方法 父组件通过操作方法修改值
      this.$emit('childedit')
    },
    // 修改ip规则信息
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.ruleForm);
          this.$http.submit({
            url: '/dispatch/rule/ip/edit',
            method: 'POST',
            data: {   // 请求数据
              id: this.ruleForm.id,
              active: this.ruleForm.active,
              keyword: this.ruleForm.keyword,
              op_type: this.ruleForm.op_type,
              to_dept_id: this.ruleForm.to_dept_id,
              to_dept_name: this.ruleForm.to_dept_name,
              to_user_id: this.ruleForm.to_user_id,
              to_user_name: this.ruleForm.to_user_name,
              notes: this.ruleForm.notes,
              start_time: this.ruleForm.times[0],   // IP分配规则开始时间
              end_time: this.ruleForm.times[1],    // IP分配规则截止时间
            }
          }).then(res => {
            // 成功后关闭弹窗
            this.centerDialogVisible = false
            // 修改ip规则成功
            // console.log(errcode, errmsg, payload);
            if (res.data[0] == 0) {
              this.$message('修改IP规则成功！');
              // 修改ip规则成功后 更新ip规则列表
              this.$emit('childeditipsuccess')
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style>
.el-form .el-form-item {
  margin-right: 30px;
}
</style>