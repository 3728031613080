// 资源管理转发组件
<template>
  <el-dialog title="转发给" :visible.sync="dialogFormVisible" style="text-align:left;" @close="close" width="20%">
    <el-form>
      <el-form-item label="部门" label-width="100px">
        <template>
          <el-cascader v-model="deptvalue" :options="deptall" :clearable="true" :props="defaultProps" @change="deptChange"></el-cascader>
        </template>
      </el-form-item>
      <el-form-item label="分配员工" label-width="100px">
        <el-select v-model="uservalue" placeholder="请选择" :clearable="true" @focus="userevent">
          <el-option v-for="(item, index) in userall" :key="index" :label="item.name" :value="item.user_id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="determine">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'leadfollowhistforwarding',     // 资源跟进中的转发
  props: ["zfshow", "xzar"],
  watch: {
    // 监听父组件传递值的变化 赋值给子组件
    zfshow (n) {
      this.dialogFormVisible = n;
    }
  },
  data () {
    return {
      dialogFormVisible: false,   // 组件是否显示
      userinfo: [],   // 当前用户信息
      deptvalue: '',  // 部门树选中的值
      deptall: [],  // 部门树
      defaultProps: { // 层级选择器值 对拿到的部门树数据做处理
        //可以选中一级内容
        checkStrictly: true,
        //数据源parantList中的cat_id做数据绑定
        value: 'dept_id',
        //数据源parantList的cat_name渲染出来的内容
        label: 'dept_name',
        //数据源parantList的children做嵌套
        children: 'children',
        // 数组选中的值只会打印最后一项
        emitPath: false,
      },
      uservalue: '',    // 用户选树中的值
      userall: [],    // 用户树

    }
  },
  created () {
    /**
     * 取出存储在缓存中的用户信息
     * JSON.parse(localStorage.getItem('userinfo'))
     */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // console.log("用户信息:", this.userinfo);
    // 默认的部门
    this.deptvalue = this.userinfo.dept_id;

    // 拿到用作下来选择的部门树
    // 部门树
    this.$http.submit({
      url: '/dept/thistree',
      method: 'POST',
      data: {   // 请求数据
        dept_id: this.userinfo.dept_id
      }
    }).then(res => {
      // 将用户列表赋值到页面
      this.deptall.push(res.data[2]);
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    close () {    // 用于关闭弹窗
      // 向父组件传递一个方法 父组件通过操作方法修改值
      this.$emit('childzfshow')
    },

    // 部门选中信息修改后
    deptChange (value) {    // 部门选中的值
      this.deptvalue = value;      // 将选中的部门id赋值给默认值
      this.uservalue = ''

    },

    // 员工选中信息修改后
    userevent () {    // 用户选项打开后执行
      this.$http.submit({
        url: '/deptuser/all',
        method: 'POST',
        data: {   // 请求数据
          dept_id: this.deptvalue
        }
      }).then(res => {
        // 通过部门id获取员工信息
        this.userall = res.data[2];
      }).catch(err => {
        console.log(err);
      })
    },

    // 确认转发
    determine () {
      // console.log(this.xzar);
      this.close();   // 关闭弹窗
      if (this.deptvalue == '' && this.uservalue == '') {
        console.log('请选择分配部门或员工信息!');
      } else {
        this.$http.submit({
          url: '/lead/dispatch/new',
          method: 'POST',
          data: {   // 请求数据
            leads: this.xzar,   // 当前选中数据id
            from_dept_id: this.userinfo.dept_id,    // 当前部门id
            to_dept_id: this.deptvalue,    // 目标部门id
            to_user_id: this.uservalue,    // 目标用户id
          }
        }).then(res => {
          // 数据
          console.log('进线分配成功后：', res);
          this.$emit('fpcg', this.xzar);    // 当分配成功后将分配的id传给父组件 进线列表删除
          this.close();   // 分配成功后关闭弹窗
          this.$message('进线分配成功！');
        }).catch(err => {
          console.log(err);
        })
      }
    },

    // 取消转发
    cancel () {
      // this.$emit('fpcg', this.xzar);    // 当分配成功后将分配的id传给父组件 进线列表删除
      this.close();   // 分配成功后关闭弹窗
    }
  }
}
</script>