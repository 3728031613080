// 表单组件
<template>
  <div>
    <el-form class="elform efil" :mobel="myfields" label-width="150px" :inline="true">
      <el-form-item class="elformitem" v-for="(item, index) in myfields" :key="index" :label="item.label + ':'">
        <div v-if="item.type == ''">
          <el-input v-model="item.value" :readonly="true" :placeholder="item.placeholder" :value="item.value == null ? '' : item.value"></el-input> <br>
          <span style="color: red; font-size:12px;">
            {{item.utime == null ? '' : item.utime}}
            {{item.user_name == null ? '' : item.user_name}}
            {{item.user_id == null ? '' : '(' + item.user_id + ')'}}
          </span>
        </div>
        <div v-else-if="item.type == 'enum'">
          <el-radio class="radios" v-for="(itemI, indexI) in JSON.parse(item.values)" :key="indexI" v-model="item.value" :value="item.value === null ? item.default : item.value" :label="indexI.toString()">
            {{itemI}}
          </el-radio> <br>
          <span style="color: red; font-size:12px;">
            {{item.utime == null ? '' : item.utime}}
            {{item.user_name == null ? '' : item.user_name}}
            {{item.user_id == null ? '' : '(' + item.user_id + ')'}}
          </span>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'formcomponent',
  props: {
    leadid: {
      type: String
    },
  },
  data () {
    return {
      myfields: [],
    }
  },
  created () {
    let myfields = {};

    // 返回表单所有字段
    this.$http.submit({
      url: '/follow/data/form',
      method: 'POST',
      data: {   // 请求数据
        form_id: 1, // form类型
      }
    }).then(res => {
      // console.log('获取表单所有字段', res);
      // console.log('form', res.data[2]);
      // 数据
      let fields = res.data[2];
      fields.forEach(field => {
        myfields[field["field"]] = field;
      })
      // console.log(Object.keys(myfields));
      let hq = Object.keys(myfields)
      // console.log(hq);
      //  先获取表单字段 后获取表单所有字段值
      this.$http.submit({
        url: '/follow/data/getall',
        method: 'POST',
        data: {   // 请求数据
          lead_id: this.leadid, // form类型
          // fields: ["jieting", "jiawei", "wx"],
          fields: hq,
        }
      }).then(res => {
        // console.log('getall', res);
        // 数据
        let rows = res.data[2];
        rows.forEach(row => {
          // console.log('row:', row.field);
          if (row.field in myfields) {
            // 表单数组值追加
            myfields[row["field"]]["value"] = row["value"];
            myfields[row["field"]]["user_name"] = row["user_name"];
            myfields[row["field"]]["user_id"] = row["user_id"];
            myfields[row["field"]]["utime"] = row["utime"];
          }

        });
        // console.log("myfields", myfields);
        this.myfields = myfields;
      }).catch(err => {
        console.log(err);
      })
    }).catch(err => {
      console.log(err);
    })
  },
}
</script>
<style>
.el-form-item .radios {
  /* 当查看详情时  单选框为不可选中状态 - 只读 */
  pointer-events: none;
}
.elform .elformitem {
  width: 520px;
}
.efil .el-form-item {
  margin-bottom: 5px;
}
.efil .el-form-item .el-form-item__label {
  font-weight: bold;
  color: blue;
  line-height: 30px;
}
.efil .el-form-item__content {
  line-height: 30px;
}

.efil .el-form-item__content .el-input .el-input__inner {
  line-height: 30px;
  height: 30px;
}
</style>
