// 线索回收
<template>
  <div>
    <list></list>
  </div>
</template>
<script>
import list from './list.vue'   // 线索回收
export default {
  name: 'recycling',    // 部门经理菜单 - 线索回收
  data () {
    return {

    }
  },
  components: {
    list,   // 线索回收
  }
}
</script>