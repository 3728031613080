// 这是部门设置容器
<template>
  <el-container>
    <el-header style="padding: 0">
      <!-- 新增部门 -->
      <el-button type="primary" @click="addshow = true">新增部门</el-button>
      <!-- 修改用户信息 -->
      <!-- <el-button type="primary" @click="editshow = true">修改部门信息</el-button> -->
      <!-- 部门列表 -->
      <el-button type="primary" @click="listevent">部门列表</el-button>
    </el-header>
    <el-main style="padding: 0">
      <add :addshow="addshow" @childadd="childadd"></add>
      <edit :editshow="editshow" @childedit="childedit"></edit>
      <all :key="timer"></all>
    </el-main>
  </el-container>
</template>
<script>
import add from './add.vue'   // 新增部门
import edit from './edit.vue'   // 编辑部门
import all from './all.vue'   // 所有部门
export default {
  name: 'deptcontainer',  // 部门容器
  data () {
    return {
      addshow: false,   // 新增部门弹窗是否显示
      editshow: false,   // 修改部门信息
      timer: '', // 点击部门列表时更新组件
    }
  },
  components: {
    add,  // 新增部门
    edit,   // 修改部门信息
    all,    // 所有部门
  },
  methods: {
    listevent () {    // 用户列表按钮点击事件
      console.log(1);
      this.timer = new Date().getTime()
    },
    // 子组件传递过来的方法
    childadd () {   // 新增部门子组件传递过来的方法
      this.addshow = false
    },
    childedit () {    // 修改部门信息子组件传递过来的方法
      this.editshow = false
    },
  }
}
</script>