// 首页容器
<template>
  <div>这是系统首页</div>
</template>
<script>
export default {
  name: 'home',
  data () {
    return {

    }
  }
}
</script>