// 已分配进线
<template>
  <div>
    <el-table :data="listdone" style="width: 100%" max-height="750" :row-class-name="tableRowClassName" :header-cell-style="{ background: '#333744' }">
      <el-table-column prop="lead_id" label="编号" width="120">
      </el-table-column>
      <!-- <el-table-column prop="follow_status" :show-overflow-tooltip="true" label="状态">
      </el-table-column> -->
      <el-table-column label="资源进线时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.date + ' ' + scope.row.time }}
        </template>
      </el-table-column>
      <el-table-column prop="mask_mobile" :show-overflow-tooltip="true" label="电话">
      </el-table-column>
      <!-- <el-table-column prop="keywords" :show-overflow-tooltip="true" label="关键字">
      </el-table-column> -->
      <!-- <el-table-column prop="dept1_name" :show-overflow-tooltip="true" label="一级部门">
      </el-table-column>
      <el-table-column prop="dept2_name" :show-overflow-tooltip="true" label="二级部门">
      </el-table-column>
      <el-table-column prop="dept3_name" :show-overflow-tooltip="true" label="三级部门">
      </el-table-column>
      <el-table-column prop="name" :show-overflow-tooltip="true" label="业务员">
      </el-table-column> -->
      <!-- <el-table-column prop="path" :show-overflow-tooltip="true" label="来源">
      </el-table-column> -->
      <el-table-column prop="account" :show-overflow-tooltip="true" label="类型">
      </el-table-column>
      <!-- <el-table-column prop="kh_name" :show-overflow-tooltip="true" label="姓名">
      </el-table-column> -->
      <!-- <el-table-column prop="valid" :show-overflow-tooltip="true" label="性质">
      </el-table-column> -->
      <el-table-column prop="dept_id" :show-overflow-tooltip="true" label="部门ID">
      </el-table-column>
      <el-table-column prop="user_id" :show-overflow-tooltip="true" label="员工ID">
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="paging">
      <!-- 
        size-change 当前条数发送改变时
        current-change 当前页数发生变化时
        current-page 当前页数
       -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="total, prev, pager, next, sizes" :total="count" prev-text="上1页" next-text="下1页">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: 'listdone',   // 已分配进线
  data () {
    return {
      currentPage: 1,    // 当前页 
      pageSize: 20,   // 每页显示条数
      count: 0,    // 总个数
      listdone: [],   // 数据
      userinfo: [],   // 用户信息
    }
  },
  // 列出用户进线
  created () {
    /**
     * 取出存储在缓存中的用户信息
     * JSON.parse(localStorage.getItem('userinfo'))
     */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // console.log("用户信息:", this.userinfo);

    // 取出当前用户的资源
    this.$http.submit({
      url: '/lead/pool/done',
      method: 'POST',
      data: {   // 请求数据
        page: 1,
        pagesize: 20,
        user_id: this.userinfo.user_id,   // 用户id
      }
    }).then(res => {
      res = res.data[2];
      this.currentPage = res.page;  // 当前页数
      // 将新增进线列表存到未分配进线
      this.listdone = res.data;  // 进线数据
      this.count = res.count;   // 总条数

      if (this.listdone == '' || this.listdone == null || this.listdone == []) {
        console.log('没有部门员工');
      } else {
        this.listdone.forEach(item => {
          if (item.follow_status == 0) {
            item.follow_status = '未跟进'
          } else if (item.follow_status == 1) {
            item.follow_status = '跟进中'
          } else if (item.follow_status == 2) {
            item.follow_status = '有意向'
          } else if (item.follow_status == 3) {
            item.follow_status = '无意向'
          } else if (item.follow_status == 4) {
            item.follow_status = '成功'
          } else if (item.follow_status == 5) {
            item.follow_status = '部门公海'
          }
        })
      }
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    // 根据跟进状态改变行颜色
    // tableRowClassName ({ row, rowIndex }) {
    //   let index = rowIndex + 1;
    //   if (row.follow_status == '未跟进') {
    //     if (index % 2 == 0) {
    //       return 'warning-row'
    //     }
    //     return
    //   } else if (row.follow_status == '跟进中') {
    //     return 'gjz-row'
    //   } else if (row.follow_status == '有意向') {
    //     return 'yyx-row'
    //   } else if (row.follow_status == '无意向') {
    //     return 'wyx-row'
    //   } else if (row.follow_status == '成功') {
    //     return 'cg-row'
    //   }
    // },
    tableRowClassName ({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return 'warning-row'
      }
    },
    // 页面列表显示条数发送变化后
    handleSizeChange: function (pageSize) {
      // console.log('页面条数发生变化', pageSize);
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currentPage);
      this.$http.submit({
        url: '/lead/pool/done',
        method: 'POST',
        data: {   // 请求数据
          pagesize: this.pageSize
        }
      }).then(res => {
        // 将新增进线列表存到未分配进线
        res = res.data[2];
        this.listdone = res.data;  // 进线数据
        this.count = res.count;   // 总条数
        if (this.listdone == '' || this.listdone == null || this.listdone == []) {
          console.log('没有部门员工');
        } else {
          this.listdone.forEach(item => {
            if (item.follow_status == 0) {
              item.follow_status = '未跟进'
            } else if (item.follow_status == 1) {
              item.follow_status = '跟进中'
            } else if (item.follow_status == 2) {
              item.follow_status = '有意向'
            } else if (item.follow_status == 3) {
              item.follow_status = '无意向'
            } else if (item.follow_status == 4) {
              item.follow_status = '成功'
            } else if (item.follow_status == 5) {
              item.follow_status = '部门公海'
            }
          })
        }
      }).catch(err => {
        console.log(err);
      })
    },
    // 列表页数发生变化后
    handleCurrentChange: function (currentPage) {
      // console.log('页数发送变化', currentPage);
      this.currentPage = currentPage;
      this.$http.submit({
        url: '/lead/pool/done',
        method: 'POST',
        data: {   // 请求数据
          page: this.currentPage,
          pagesize: this.pageSize
        }
      }).then(res => {
        // 将新增进线列表存到未分配进线
        res = res.data[2];
        this.currentPage = res.page;  // 当前页
        this.listdone = res.data;  // 进线数据
        this.count = res.count;   // 总条数
        if (this.listdone == '' || this.listdone == null || this.listdone == []) {
          console.log('没有部门员工');
        } else {
          this.listdone.forEach(item => {
            if (item.follow_status == 0) {
              item.follow_status = '未跟进'
            } else if (item.follow_status == 1) {
              item.follow_status = '跟进中'
            } else if (item.follow_status == 2) {
              item.follow_status = '有意向'
            } else if (item.follow_status == 3) {
              item.follow_status = '无意向'
            } else if (item.follow_status == 4) {
              item.follow_status = '成功'
            } else if (item.follow_status == 5) {
              item.follow_status = '部门公海'
            }
          })
        }
      }).catch(err => {
        console.log(err);
      })
    },
  }
}
</script>