// 推广页列表
<template>
  <div>
    <el-table :data="leadipall" style="width: 100%" max-height="750" :header-cell-style="{ background: '#333744' }">
      <el-table-column prop="id" :show-overflow-tooltip="true" label="编号" width="120">
      </el-table-column>
      <el-table-column prop="active" label="是否启用" width="120">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.active" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949" @change="changeSwitch(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column prop="keyword" :show-overflow-tooltip="true" label="ip区域名"  width="150">
      </el-table-column>
      <el-table-column prop="to_dept_id" :show-overflow-tooltip="true" label="部门ID" width="80">
      </el-table-column>
      <el-table-column prop="to_dept_name" :show-overflow-tooltip="true" label="部门名称" width="180">
      </el-table-column>
      <el-table-column prop="to_user_id" :show-overflow-tooltip="true" label="员工ID" width="80">
      </el-table-column>
      <el-table-column prop="to_user_name" :show-overflow-tooltip="true" label="员工名" width="180">
      </el-table-column>
      <el-table-column prop="start_time" :show-overflow-tooltip="true" label="生效时间">
      </el-table-column>
      <el-table-column prop="end_time" :show-overflow-tooltip="true" label="截止时间">
      </el-table-column>
      <el-table-column prop="notes" :show-overflow-tooltip="true" label="规则备注"   width="300">
      </el-table-column>
      <el-table-column label="操作" :show-overflow-tooltip="true" width="160">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.$index, leadipall)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, leadipall)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: 'leadipall',    // ip规则列表
  data () {
    return {
      leadipall: [], // ip规则列表
    }
  },
  created () {

    // 页面加载就获取数据
    this.$http.submit({
      url: '/dispatch/rule/ip/all',
      method: 'POST',
      data: {   // 请求数据

      }
    }).then(res => {
      // 将IP规则列表赋值到页面
      let [errcode, errmsg, payload] = res.data;
      console.log(errcode, errmsg, payload);
      // console.log("是否启用：", typeof this.leadipall[0].active, ' === ', this.leadipall[1].active);
      if (errcode == 0) {
        this.leadipall = payload;
      }
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {

    // 是否启用按钮 - 点击按钮切换是否启用
    changeSwitch (row) {
      console.log(row.id, '====', row.active);
      this.$http.submit({
        url: '/dispatch/rule/ip/edit',
        method: 'POST',
        data: {   // 请求数据
          active: row.active,   // 是否启用 0-禁用 1-启用
          id: row.id,   // ip分配规则id
        }
      }).then(res => {
        // 修改ip分配规则是否启动
        res
        // console.log('leadAutoSubmit：', res);
      }).catch(err => {
        console.log(err);
      })
    },

    // 删除按钮 点击删除ip规则
    handleDelete (index, row) {
      // console.log('删除', row[index].id);
      this.$http.submit({
        url: '/dispatch/rule/ip/remove',
        method: 'POST',
        data: {   // 请求数据
          id: row[index].id,   // 用户ID
        }
      }).then(res => {
        // 删除用户成功
        row.splice(index, 1);   // 删除一行数据
        this.$message('"删除ip规则信息成功!"');
        res
        // console.log("删除ip规则信息成功!", res);
      }).catch(err => {
        console.log(err);
      })
    },
    handleEdit (index, row) {
      // console.log('编辑', row[index].id);
      // 将编辑的ip规则ID传递给兄弟组件-编辑ip规则
      this.bus.$emit('ipid', row[index].id)

      this.bus.$emit('editshow', true)
    }
  }
}
</script>
