// 推广按照IP分配到指定部门
<template>
  <el-container>
    <el-header style="padding: 0">
      <!-- 新增IP规则 -->
      <el-button type="primary" @click="addshow = true">新增IP规则</el-button>
      <!-- 规则列表 -->
      <el-button type="primary" @click="allevent">规则列表</el-button>
    </el-header>
    <el-main style="padding: 0">
      <!-- 
       add  新增IP规则
       edit   编辑IP规则
       all   规则列表
     -->
      <add :addshow="addshow" @childadd="childadd" @childaddipsuccess="childaddipsuccess"></add>
      <edit :editshow="editshow" @childedit="childedit" @childeditipsuccess="childeditipsuccess"></edit>
      <all :key="timer"></all>
    </el-main>
  </el-container>
</template>
<script>
import all from './all.vue'   // IP规则列表
import add from './add.vue'   // IP规则新增
import edit from './edit.vue'   // 编辑IP规则
export default {
  name: 'leadpage',   // IP规则设置
  data () {
    return {
      addshow: false,     // 新增IP规则弹窗是否显示
      editshow: false,  // 修改IP规则信息弹窗是否显示
      timer: '',    // 点击刷新组件
    }
  },
  components: {
    all,    // 所有员工信息
    add,  // 新增IP规则组件
    edit,   // 修改IP规则信息组件
  },
  methods: {
    // 子组件传递过来的方法
    childadd () {   // 新增IP规则子组件传递过来的方法
      this.addshow = false
    },
    childaddipsuccess () { // 新增IP规则成功后传递过来的方法
      this.timer = new Date().getTime()
    },
    childedit () {    // 修改IP规则信息子组件传递过来的方法
      this.editshow = false
    },
    childeditipsuccess () { // 修改IP规则成功后传递过来的方法
      this.timer = new Date().getTime()
    },
    allevent () {    // IP规则列表按钮点击事件刷新组件
      this.timer = new Date().getTime()
    }
  }
}
</script>
<style>
.el-container .el-header {
  width: 100%;
  position: relative;
  background: none;
  text-align: initial;
}
</style>