// 新增员工
<template>
  <el-dialog title="新增员工" :visible.sync="centerDialogVisible" @close="close" width="35%" center>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" :inline="true" class="demo-ruleForm">
      <el-form-item label="用户id" prop="user_id">
        <el-input v-model="ruleForm.user_id"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="登录名" prop="login_id">
        <el-input v-model="ruleForm.login_id"></el-input>
      </el-form-item>
      <el-form-item label="登录密码" prop="pwdhash">
        <el-input v-model="ruleForm.pwdhash"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkpwdhash">
        <el-input v-model="ruleForm.checkpwdhash"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="ruleForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="email" prop="email">
        <el-input v-model="ruleForm.email"></el-input>
      </el-form-item>
      <el-form-item label="微信号" prop="wxid">
        <el-input v-model="ruleForm.wxid"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="ruleForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="分机" prop="ext">
        <el-input v-model="ruleForm.ext"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-input v-model="ruleForm.gender"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idnum">
        <el-input v-model="ruleForm.idnum"></el-input>
      </el-form-item>
      <el-form-item label="生日" prop="birthday">
        <el-input v-model="ruleForm.birthday"></el-input>
      </el-form-item>
      <el-form-item label="头像" prop="avatar">
        <el-input v-model="ruleForm.avatar"></el-input>
      </el-form-item>
      <el-form-item label="家庭地址" prop="home_address">
        <el-input v-model="ruleForm.home_address"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'useradd',  // 新增员工
  props: {  // 获取父组件传递过来的值
    addusershow: {
      type: Boolean,
    },
  },
  watch: {
    // 监听父组件传递值的变化 赋值给子组件
    addusershow (n) {
      this.centerDialogVisible = n;
    }
  },
  data () {
    // 判断两次输入密码是否正确
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkpwdhash !== '') {
          this.$refs.ruleForm.validateField('checkpwdhash');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pwdhash) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      centerDialogVisible: false, // 组件是否显示
      ruleForm: {
        user_id: '',  // 用户id
        name: '',   // 姓名 -- V
        login_id: '',   // 登录名 -- V
        pwdhash: '',   // 登录密码 -- V
        checkpwdhash: '',  // 确认密码
        mobile: '',   // 手机号 -- V
        email: '',    // 邮箱
        wxid: '',   // 微信号
        phone: '',   // 联系电话
        ext: '',    // 分机
        gender: '',   // 性别
        idnum: '',    // 身份证号
        birthday: '',   // 生日
        avatar: '',   // 头像
        home_address: '',   // 家庭地址
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          // { min: 3, message: '长度必须大于3位', trigger: 'blur' }
        ],
        login_id: [
          { required: true, message: '请输入登录名', trigger: 'blur' },
          { min: 4, message: '长度必须大于4位', trigger: 'blur' }
        ],
        pwdhash: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        checkpwdhash: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
      }
    }
  },
  methods: {

    // 子组件向父组件传值
    close () {
      // 向父组件传递一个方法 父组件通过操作方法修改值
      this.$emit('childadduser')
    },
    // 新增
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 新增用户
          // 登录密码
          let pwdhash = this.$md5(this.ruleForm.login_id + this.ruleForm.pwdhash);
          this.$http.submit({
            url: '/user/add',
            method: 'POST',
            data: {   // 请求数据
              user_id: this.ruleForm.user_id,   // 用户id
              name: this.ruleForm.name,   // 姓名
              login_id: this.ruleForm.login_id,   // 登录名
              pwdhash: pwdhash,  // 登录密码
              mobile: this.ruleForm.mobile,  // 手机号
              email: this.ruleForm.email,   // 邮箱
              wxid: this.ruleForm.wxid,   // 微信号
              phone: this.ruleForm.phone,   // 联系电话
              ext: this.ruleForm.ext,   // 分机
              gender: this.ruleForm.gender, // 性别
              idnum: this.ruleForm.idnum, //身份证号
              birthday: this.ruleForm.birthday, // 生日
              avatar: this.ruleForm.avatar, // 头像
              home_address: this.ruleForm.home_address  // 家庭地址
            }
          }).then(res => {
            // 成功后关闭弹窗
            this.close()
            // 新增用户成功
            this.$message('新增用户成功！');
            console.log("新增用户成功！", res)
          }).catch(err => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style>
.el-form .el-form-item {
  margin-right: 30px;
}
.el-input--suffix .el-input__inner {
  padding-right: 15px;
}
</style>