// 部门用户容器
<template>
  <el-container>
    <el-header style="padding: 0">
      <!-- 部门新增用户 -->
      <el-button type="primary" @click="addshow = true">新增部门员工</el-button>
      <!-- 修改部门用户信息 -->
      <!-- <el-button type="primary" @click="editshow = true">修改部门用户信息</el-button> -->
      <!-- 删除部门用户信息 -->
      <!-- <el-button type="primary" @click="removeshow = true">删除部门用户</el-button> -->
      <!-- 部门列表 -->
      <el-button type="primary" @click="allevent">本部门员工</el-button>
      <!-- 部门下所有员工 -->
      <el-button type="primary" @click="listallevent">下级所有员工</el-button>
    </el-header>
    <el-main style="padding: 0">
      <add :addshow="addshow" @childadd="childadd"></add>
      <edit :editshow="editshow" @childedit="childedit"></edit>
      <!-- <remove :removeshow="removeshow" @childremove="childremove"></remove> -->
      <all v-if="allevents" :key="timer"></all>
      <listall v-else :key="timer"></listall>
    </el-main>
  </el-container>
</template>
<script>
import add from './add.vue'   // 添加部门员工
// import remove from './remove.vue' // 删除部门员工
import edit from './edit.vue' // 设置部门员工
import all from './all.vue'   // 列出部门员工
import listall from './listall.vue'   // 列出所有部门和子部门员工

export default {
  name: 'deptusercontainer',  // 部门用户容器
  data () {
    return {
      timer: '', // 点击部门列表时更新组件
      addshow: false,   // 新增部门员工信息
      editshow: false,  // 修改部门用户信息
      // removeshow: false,  // 删除部门用户
      allevents: true, // 部门员工显示切换
    }
  },
  components: {
    add,  // 部门新增用户
    edit, // 修改部门用户信息
    // remove, // 删除部门用户
    all,   // 部门所有员工
    listall,  // 下级所有部门员工

  },
  methods: {
    listevent () {    // 用户列表按钮点击事件
      console.log(1);
      this.timer = new Date().getTime()
    },
    // 子组件传递过来的方法
    childadd () {   // 新增部门员工子组件传递过来的方法
      this.addshow = false;
      this.timer = new Date().getTime()
    },
    childedit () {    // 修改部门用户信息子组件传递过来的方法
      this.editshow = false;
      this.timer = new Date().getTime()
    },
    childremove () {   // 修改部门用户信息子组件传递过来的方法
      this.removeshow = false;
      this.timer = new Date().getTime()
    },
    allevent () {    // 修改部门用户信息子组件传递过来的方法
      this.allevents = true;
      this.timer = new Date().getTime()

    },
    listallevent () {    // 修改部门用户信息子组件传递过来的方法
      this.allevents = false;
      this.timer = new Date().getTime()

    },
  }
}
</script>