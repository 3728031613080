// 网站设置容器
<template>
  <el-container>
    <el-header style="padding: 0">
      <!-- 新增网站 -->
      <el-button type="primary" @click="addshow = true">新增网站</el-button>
      <!-- 编辑网站 -->
      <!-- <el-button type="primary" @click="editshow = true">编辑网站</el-button> -->
      <!-- 网站列表 -->
      <el-button type="primary" @click="allevent">网站列表</el-button>
    </el-header>
    <el-main style="padding: 0">
      <!-- 
       add  新增网站
       edit   编辑网站
       all   网站列表
     -->
      <add :addshow="addshow" @childadd="childadd"></add>
      <edit :editshow="editshow" @childedit="childedit"></edit>
      <all :key="timer"></all>
    </el-main>
  </el-container>
</template>
<script>
import all from './all.vue'   // 网站列表
import add from './add.vue'   // 网站新增
import edit from './edit.vue'   // 编辑网站
export default {
  name: 'leadpage',   // 网站设置
  data () {
    return {
      addshow: false,     // 新增用户弹窗是否显示
      editshow: false,  // 修改用户信息弹窗是否显示
      timer: '',    // 点击刷新组件
    }
  },
  components: {
    all,    // 所有员工信息
    add,  // 新增用户组件
    edit,   // 修改用户信息组件
  },
  methods: {
    // 子组件传递过来的方法
    childadd () {   // 新增用户子组件传递过来的方法
      this.addshow = false
    },
    childedit () {    // 修改用户信息子组件传递过来的方法
      this.editshow = false
    },
    allevent () {    // 用户列表按钮点击事件刷新组件
      this.timer = new Date().getTime()
    }
  }
}
</script>
<style>
.el-container .el-header {
  width: 100%;
  position: relative;
  background: none;
  text-align: initial;
}
</style>