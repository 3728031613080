// 这是部门用户管理容器
<template>
  <el-table :data="deptlist" style="width: 100%" max-height="750" :header-cell-style="{ background: '#333744' }">
    <el-table-column prop="user_id" :show-overflow-tooltip="true" label="员工id">
    </el-table-column>
    <el-table-column prop="name" :show-overflow-tooltip="true" label="员工名">
    </el-table-column>
    <el-table-column prop="login_id" :show-overflow-tooltip="true" label="登录名">
    </el-table-column>
    <!-- <el-table-column prop="position" :show-overflow-tooltip="true" label="员工职务">
    </el-table-column> -->
    <el-table-column :show-overflow-tooltip="true" label="员工职务">
      <template slot-scope="scope">
        {{ scope.row.position == 0 ? "普通职员" : scope.row.position == 1 ? "初级职员" : scope.row.position == 2 ? "高级职员" : scope.row.position  == 3 ? "副主管" : "主管" }}
      </template>
    </el-table-column>
    <el-table-column :show-overflow-tooltip="true" label="一级部门">
      <template slot-scope="scope">
        {{ scope.row.dept1_name == null ? '' : scope.row.dept1_name }}
        {{ scope.row.dept1_id==null ? '' : '(' + scope.row.dept1_id + ')' }}
      </template>
    </el-table-column>
    <el-table-column :show-overflow-tooltip="true" label="二级部门">
      <template slot-scope="scope">
        {{ scope.row.dept2_name == null ? '' : scope.row.dept2_name }}
        {{ scope.row.dept2_id==null ? '' : '(' + scope.row.dept2_id + ')' }}
      </template>
    </el-table-column>
    <el-table-column :show-overflow-tooltip="true" label="三级部门">
      <template slot-scope="scope">
        {{ scope.row.dept3_name == null ? '' : scope.row.dept3_name }}
        {{ scope.row.dept3_id==null ? '' : '(' + scope.row.dept3_id + ')' }}
      </template>
    </el-table-column>
    <el-table-column label="操作" width="160">
      <template slot-scope="scope">
        <el-button size="mini" type="primary" @click="handleEdit(scope.$index, deptlist)">编辑</el-button>
        <el-button size="mini" type="danger" @click="handleDelete(scope.$index, deptlist)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  name: 'deptuserall',  // 列出部门和子部门所有员工
  data() {
    return {
      userinfo: {},   // 获取用户信息
      deptlist: [], // 页面显示列表
    }
  },
  created() {
    this.bus.$on('userlistall', data => {    // 修改员工成功后
      this.deptlist = data
    })
    /**
    * 取出存储在缓存中的用户信息
    * JSON.parse(localStorage.getItem('userinfo'))
    */
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.userinfo = userinfo;
    // console.log("用户信息:", this.userinfo);
    // 页面加载就获取数据
    this.$http.submit({
      url: '/deptuser/listall',
      method: 'POST',
      data: {   // 请求数据
        dept_id: this.userinfo.dept_id
      }
    }).then(res => {
      // 将用户列表赋值到页面
      // console.log(res);
      this.deptlist = res.data[2];
      if (this.deptlist == '' || this.deptlist == null || this.deptlist == []) {
        console.log('没有部门员工');
      } else {
        // this.deptlist.forEach(item => {
        //   if (item.position == 0) {
        //     item.position = '普通职员'
        //   } else if (item.position == 1) {
        //     item.position = '初级职员'
        //   } else if (item.position == 2) {
        //     item.position = '高级职员'
        //   } else if (item.position == 3) {
        //     item.position = '副主管'
        //   } else if (item.position == 4) {
        //     item.position = '主管'
        //   }
        // })
      }
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    // 删除按钮 点击删除部门用户
    handleDelete(index, row) {
      // console.log('删除', row[index].user_id);
      this.$confirm(
        `即将删除 
        <span style="color: red; font-size: 20px; font-weight: bold;">${row[index].name}</span>
         员工，请您确保此员工名下的资源已被部门回收。是否继续？
        `,
        // "即将删除【" + row[index].name + "】员工，请您确保此员工名下的资源已被部门回收。是否继续？",
        '部门员工删除',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          type: 'warning'
        }).then(() => {
          // console.log("确认删除：", row[index].name);
          // 删除 点击确认则 删除
          this.$http.submit({
            url: '/deptuser/remove',
            method: 'POST',
            data: {   // 请求数据
              dept_id: this.userinfo.dept_id,   // 部门ID
              user_id: row[index].user_id,   // 员工ID
            }
          }).then(res => {
            // 删除部门员工成功
            if (res.data[0] == 0) {
              row.splice(index, 1);
              this.$message({
                type: 'success',
                message: '删除部门员工成功！'
              });
            } else {
              // console.log("删除失败");
              // this.$message(res.data[1]);
              this.$message({
                type: 'info',
                message: res.data[1]
              });
            }
            // console.log("删除部门用户成功！", res)
          }).catch(err => {
            console.log(err);
          })
        }).catch(() => {
          // console.log("取消删除：", row[index].name);
          // 删除点击取消则退出
          this.$message({
            type: 'info',
            message: '取消部门员工删除！'
          });
        });
    },
    // 编辑按钮 点击根据用户id修改部门信息
    handleEdit(index, row) {
      console.log('编辑', row[index].user_id);

      // 将编辑的网站ID传递给兄弟组件-编辑网站
      this.bus.$emit('deptuserinfo', row[index])

      this.bus.$emit('editshow', true)
    }
  }
}
</script>