// 列出所有部门
<template>
  <el-table :data="deptall" style="width: 100%" :header-cell-style="{ background: '#333744' }">
    <el-table-column prop="dept_id" :show-overflow-tooltip="true" label="部门ID">
    </el-table-column>
    <el-table-column prop="dept_name" :show-overflow-tooltip="true" label="部门名">
    </el-table-column>
    <el-table-column prop="dept_level" :show-overflow-tooltip="true" label="部门级别">
    </el-table-column>
    <el-table-column prop="parent_id" :show-overflow-tooltip="true" label="上级部门ID">
    </el-table-column>
    <el-table-column prop="leader_id" :show-overflow-tooltip="true" label="部门领导ID">
    </el-table-column>
    <el-table-column prop="leader_name" :show-overflow-tooltip="true" label="部门领导名">
    </el-table-column>
    <el-table-column label="一级部门" :show-overflow-tooltip="true">
      <template slot-scope="scope">
        {{ scope.row.dept1_name + '(' + scope.row.dept1_id + ')' }}
      </template>
    </el-table-column>
    <el-table-column label="二级部门" :show-overflow-tooltip="true">
      <template slot-scope="scope">
        {{ scope.row.dept2_name == null ? '' : scope.row.dept2_name }} 
        {{ scope.row.dept2_id==null ? '' : '(' + scope.row.dept2_id + ')'  }}
      </template>
    </el-table-column>
    <el-table-column label="三级部门" :show-overflow-tooltip="true">
      <template slot-scope="scope">
        {{ scope.row.dept3_name == null ? '' : scope.row.dept3_name }} 
        {{ scope.row.dept3_id==null ? '' : '(' + scope.row.dept3_id + ')'  }}
      </template>
    </el-table-column>
    <!-- <el-table-column prop="dept_fullname" :show-overflow-tooltip="true" label="部门全名">
    </el-table-column> -->
    <el-table-column label="操作" width="160">
      <template slot-scope="scope">
        <el-button size="mini" type="primary" @click="handleEdit(scope.$index, deptall)">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  name: 'deptall',    // 列出所有部门
  data () {
    return {
      deptall: [], // 所有部门
    }
  },
  created () {
    // 页面加载就获取数据
    this.$http.submit({
      url: '/dept/all',
      method: 'POST',
      data: {   // 请求数据
        // pagesize: 100,
        // page: 1
      }
    }).then(res => {
      // 将所有部门赋值到页面
      // console.log(res.data);
      this.deptall = res.data[2];
    }).catch(err => {
      console.log(err);
    })
  },
  methods: {
    // 编辑部门
    handleEdit (index, row) {
      // console.log('编辑', row[index]);

      // 将修改的部门信息传递给兄弟组件-修改部门
      this.bus.$emit('deptinfo', row[index])

      this.bus.$emit('editshow', true)
    }
  }
}
</script>